import { Edge, Node, XYPosition } from '@xyflow/react';
import { map, reject } from 'lodash';
import { normalizeSourceTargetIds, ResolvedConnection, UUID } from '@senrasystems/senra-ui';
import { isLayoutNode, isLayoutPointNode, isMeasurableNode, isMeasurementEdge, isSegmentEdge } from '../types.ts';
import { SegmentEdgeData } from '../components/edges/SegmentEdge/SegmentEdge.tsx';
import { findBundle, hasBundle } from './bundles.ts';
import { MeasurementEdgeData } from '../components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { findNodeById } from '../graph/NodeFactory.ts';
import { calculateMidpoint } from './geometry.ts';
import { defaultPosition, Graph } from '../../../../types/reactFlow.ts';

/**
 * Finds a measurement edge between two nodes.
 * @param edges
 * @param sourceId
 * @param targetId
 * @returns The measurement edge if found, otherwise undefined.
 */
export const findMeasurementEdge = (
  edges: Edge[],
  sourceId: UUID,
  targetId: UUID,
): Edge<MeasurementEdgeData> | undefined => {
  const { source, target } = normalizeSourceTargetIds(sourceId, targetId);
  return edges.find(
    (edge): edge is Edge<MeasurementEdgeData> =>
      isMeasurementEdge(edge) && edge.source === source && edge.target === target,
  );
};

/**
 * Finds a path between two nodes in the graph. A path is considered valid if there is a sequence of edges connecting
 * the source node to the target node. If a bundle is provided, the path must involve edges that contain the specified
 * bundle.
 * @param nodes - The list of nodes in the graph.
 * @param edges - The list of edges in the graph.
 * @param sourceId - The ID of the source node.
 * @param targetId - The ID of the target node.
 * @param isValidNode - A function that determines if a node is valid for the path.
 * @param connection - An optional connection object that specifies a bundle to filter edges by.
 * @returns An object indicating whether a path exists and the edges that form the path.
 */
export const findPath = (
  nodes: Node[],
  edges: Edge[],
  sourceId: UUID,
  targetId: UUID,
  isValidNode: (node: Node) => boolean,
  connection?: ResolvedConnection,
): { pathExists: boolean; pathEdges: Edge<SegmentEdgeData>[] } => {
  const visited = new Set<string>();
  const stack: string[] = [sourceId];
  const predecessorMap: Record<string, Edge<SegmentEdgeData> | null> = {};

  // Initialize predecessors
  nodes.forEach((node) => (predecessorMap[node.id] = null));

  while (stack.length > 0) {
    const currentNodeId = stack.pop();
    if (!currentNodeId) continue;

    if (currentNodeId === targetId) {
      // Path exists, reconstruct the path
      const pathEdges: Edge<SegmentEdgeData>[] = [];
      let nodeId = targetId;

      // Trace back using the predecessor map
      while (predecessorMap[nodeId]) {
        const edge = predecessorMap[nodeId];
        if (!edge) {
          throw new Error(`Edge for node ${nodeId} is unexpectedly undefined.`);
        }
        pathEdges.push(edge);
        nodeId = edge.source === nodeId ? edge.target : edge.source;
      }

      // Return the path in correct order
      return { pathExists: true, pathEdges: pathEdges.reverse() };
    }

    if (!visited.has(currentNodeId)) {
      visited.add(currentNodeId);

      const connectedEdges: Edge<SegmentEdgeData>[] = edges
        .filter((edge): edge is Edge<SegmentEdgeData> => {
          // Check if the edge is of type 'Segment' and is connected to the current node
          if (!isSegmentEdge(edge) || !(edge.source === currentNodeId || edge.target === currentNodeId)) {
            return false;
          }

          // If a connection is provided, check if the edge's bundles include the connection's bundleId
          if (connection?.bundleId) {
            return !!findBundle(edge.data.bundles, connection.bundleId);
          }

          // If no bundle is provided, allow the segment to be considered
          return true;
        })
        // Filter out visited nodes
        .filter((edge) => !visited.has(edge.source === currentNodeId ? edge.target : edge.source));

      connectedEdges.forEach((edge) => {
        const nextNodeId = edge.source === currentNodeId ? edge.target : edge.source;
        const nextNode = nodes.find((node) => node.id === nextNodeId);

        if (nextNode && (isValidNode(nextNode) || nextNodeId === targetId)) {
          // Add the next node to the stack
          stack.push(nextNodeId);
          // Set the predecessor edge for this node
          predecessorMap[nextNodeId] = edge;
        }
      });
    }
  }

  // If we're here, then no path was found
  return { pathExists: false, pathEdges: [] };
};

/**
 * Get all layout nodes connected to a given node.
 * @param nodes - Array of nodes in the graph.
 * @param edges - Array of edges in the graph.
 * @param nodeId - ID of the node to find connected design part nodes for.
 * @returns Array of design part nodes connected to the specified node.
 */
export const getConnectedLayoutNodes = (nodes: Node[], edges: Edge[], nodeId: UUID): Node[] => {
  return nodes.filter(
    (node) =>
      isLayoutNode(node) &&
      edges.some(
        (edge) =>
          isSegmentEdge(edge) &&
          ((edge.source === nodeId && edge.target === node.id) || (edge.target === nodeId && edge.source === node.id)),
      ),
  );
};

/**
 * Finds all measurement edges connected to a node.
 * @param edges
 * @param nodeId
 * @returns An array of connected measurement edges.
 */
export const getConnectedMeasurements = (edges: Edge[], nodeId: UUID): Edge<MeasurementEdgeData>[] => {
  return edges.filter(
    (edge): edge is Edge<MeasurementEdgeData> =>
      isMeasurementEdge(edge) && (edge.source === nodeId || edge.target === nodeId),
  );
};

/**
 * Finds all segments connected to a node. If a bundleId is provided, returns only segments that include the bundleId.
 * @param edges - Array of edges in the graph.
 * @param nodeId - The ID of the node to find connected edges for.
 * @param bundleId - (Optional) The ID of the bundle to filter edges by.
 * @returns An array of connected segment edges, optionally filtered by bundleId.
 */
export const getConnectedSegments = (edges: Edge[], nodeId: UUID, bundleId?: string): Edge<SegmentEdgeData>[] => {
  return edges.filter((edge): edge is Edge<SegmentEdgeData> => {
    // Check if the edge is a segment and is connected to the specified node
    const isConnectedToNode = isSegmentEdge(edge) && (edge.source === nodeId || edge.target === nodeId);

    if (bundleId) {
      // If bundleId is provided, ensure the edge's bundles include the specified bundleId
      return isConnectedToNode && hasBundle((edge.data as SegmentEdgeData).bundles, bundleId);
    }

    // Return all connected segment edges if no bundleId is specified
    return isConnectedToNode;
  });
};

/**
 * Determines if a measurement edge should be created between two nodes.
 * @param nodes
 * @param edges
 * @param node1
 * @param node2
 * @returns An object indicating whether a measurement edge can be created and the edges involved.
 */
export const getMeasurementEdgePath = (
  nodes: Node[],
  edges: Edge[],
  node1: Node | UUID,
  node2: Node | UUID,
): { pathExists: boolean; pathEdges: Edge[] } => {
  // Helper function to get the Node object from nodes array or use it directly if it's already a Node
  const getNode = (nodeOrId: Node | UUID): Node | undefined =>
    typeof nodeOrId === 'string' ? nodes.find((node) => node.id === nodeOrId) : nodeOrId;

  // Resolve node1 and node2 to their Node objects
  const resolvedNode1 = getNode(node1);
  const resolvedNode2 = getNode(node2);

  // If either node is not found, return false
  if (!resolvedNode1 || !resolvedNode2) {
    return { pathExists: false, pathEdges: [] };
  }

  // Check if both nodes are measurement nodes
  if (!isMeasurableNode(resolvedNode1) || !isMeasurableNode(resolvedNode2)) {
    return { pathExists: false, pathEdges: [] };
  }

  // Check if the nodes are directly connected by a non-measurement edge
  const directlyConnected = isDirectlyConnected(resolvedNode1.id, resolvedNode2.id, edges);

  // Check if the nodes are connected through a layout point
  const { pathExists: connectedThroughLayoutPoint, pathEdges } = findPath(
    nodes,
    edges,
    resolvedNode1.id,
    resolvedNode2.id,
    isLayoutPointNode,
  );

  return { pathExists: directlyConnected || connectedThroughLayoutPoint, pathEdges };
};

/**
 * Determines the position of the new control point on the segment.
 * @param nodes
 * @param edge
 * @returns The position of the new control point.
 */
export const getMidpoint = (nodes: Node[], edge: Edge): XYPosition => {
  // Calculate the midpoint for the new node
  const sourceNode = findNodeById(nodes, edge.source);
  const targetNode = findNodeById(nodes, edge.target);

  if (!sourceNode || !targetNode) {
    // eslint-disable-next-line no-console
    console.warn(`Source or target node not found for edge ${edge.id}.`);
    return defaultPosition;
  }

  return calculateMidpoint(sourceNode.position, targetNode.position);
};

/**
 * Checks if a node is directly connected to another node via any edge.
 * @param sourceId - The ID of the source node.
 * @param targetId - The ID of the target node.
 * @param edges - The array of edges in the graph.
 * @returns True if a direct connection exists, otherwise false.
 */
export const isDirectlyConnected = (sourceId: UUID, targetId: UUID, edges: Edge[]): boolean => {
  const { source, target } = normalizeSourceTargetIds(sourceId, targetId);

  return edges.some((edge) => {
    return isSegmentEdge(edge) && edge.source === source && edge.target === target;
  });
};

/**
 * Removes all segment edges that are connected to a specified node.
 * This function modifies the `edges` array in place.
 * @param edges - The array of edges to modify.
 * @param nodeId - The ID of the node to remove connected segment edges.
 * @returns The modified edges array with the connected segment edges removed.
 */
export const removeConnectedSegments = (edges: Edge[], nodeId: UUID) => {
  // Iterate in reverse to void skipping elements when removing
  for (let i = edges.length - 1; i >= 0; i--) {
    const edge = edges[i];
    if (isSegmentEdge(edge) && (edge.source === nodeId || edge.target === nodeId)) {
      edges.splice(i, 1);
    }
  }
};

/**
 * Removes all measurement edges that are connected to a specified node.
 * This function modifies the `edges` array in place.
 * @param edges - The array of edges to modify.
 * @param nodeId - The ID of the node to remove connected measurement edges.
 * @returns The modified edges array with the connected measurement edges removed.
 */
export const removeConnectedMeasurements = (edges: Edge[], nodeId: UUID) => {
  // Iterate in reverse to void skipping elements when removing
  for (let i = edges.length - 1; i >= 0; i--) {
    const edge = edges[i];
    if (isMeasurementEdge(edge) && (edge.source === nodeId || edge.target === nodeId)) {
      edges.splice(i, 1);
    }
  }
};

/**
 * Removes nodes and connected edges from nodes and edges, and returns new nodes and edges
 * @param graph
 * @param nodesToRemove
 * @returns the graph with nodes from nodesToRemove and related edges removed
 *  */
export const removeNodesFromGraph = (graph: Graph, nodesToRemove: Node[]) => {
  if (nodesToRemove.length === 0) {
    return graph;
  }

  const { nodes, edges } = graph;
  const nodeIdsToRemoveSet = new Set(map(nodesToRemove, 'id'));
  const updatedNodes = reject(nodes, (node) => nodeIdsToRemoveSet.has(node.id));
  const updatedEdges = reject(
    edges,
    (edge) => nodeIdsToRemoveSet.has(edge.source) || nodeIdsToRemoveSet.has(edge.target),
  );

  return { nodes: updatedNodes, edges: updatedEdges };
};
