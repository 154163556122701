import { Flex } from '@chakra-ui/react';
import { useWindowManager } from '../hooks/useWindowManager.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import { generatePath } from 'react-router-dom';
import { AssemblyNavigatorWindow } from '@web/apps/Design';
import TopbarNav from '@web/components/navigation/TopbarNav.tsx';
import { DesignTabs } from './DesignTabs.tsx';
import { useDesign } from '../hooks/useDesign.tsx';

const BORDER_LINE = '2px solid';
const BORDER_COLOR = 'gray.300';

/**
 * Design component displays the Assembly Navigator on the left, and main content on the right.
 * @constructor
 */
const Design = () => {
  const { designId, isViewOnly } = useDesign();
  const { isWindowOpened } = useWindowManager();

  return (
    <Flex direction="column" overflow="hidden" height="full">
      {/* Header Section */}
      {!isViewOnly && <TopbarNav />}
      {/* Content Section */}
      {isWindowOpened(generatePath(RouteNames.DESIGNS.DESIGN_PARTS, { designId })) ? (
        <DesignTabs flex={1} />
      ) : (
        <Flex flex={1}>
          <AssemblyNavigatorWindow borderRight={BORDER_LINE} borderColor={BORDER_COLOR} zIndex="docked" />
          <DesignTabs flex={1} minWidth={0} />
        </Flex>
      )}
    </Flex>
  );
};

export default Design;
