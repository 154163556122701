import camelize from 'camelize';
import { authService } from '../services/auth-service';
import { envVars, Vars } from './environment';
import { errorToast } from './toasts';

export const BAD_REQUEST = 'Bad Request';

export function getUrl(endpoint: string): string {
  const baseUrl = envVars.get(Vars.ApiBaseUrl);
  if (!baseUrl) throw new Error('Missing required env var for API base URL');
  return `${baseUrl}${endpoint}`;
}

export async function getBaseOptions(
  method: string,
  contentType: string | null = 'application/json',
): Promise<RequestInit> {
  const headers = await getHeaders(contentType);
  return {
    method,
    headers,
  };
}

async function getHeaders(contentType: string | null): Promise<Headers> {
  const headers = new Headers();
  let accessToken;
  try {
    accessToken = await authService.getAccessToken();
  } catch (e) {}
  if (accessToken) {
    const bearer = `Bearer ${accessToken}`;
    headers.append('Authorization', bearer);
  }
  if (contentType != null) {
    headers.append('Content-Type', contentType);
  }
  return headers;
}

export async function doRequest<T>(method: string, url: string, body?: unknown) {
  const options = await getBaseOptions(method, body instanceof FormData ? null : 'application/json');
  if (body) {
    options.body = body as BodyInit;
  }
  const res = await fetch(url, options);

  try {
    const json = camelize(await res.json());
    if (res.ok) {
      return json as T;
    } else {
      if ([403, 404].includes(res.status)) {
        window.location.assign('/');
      } else {
        errorToast(json.message);
      }
    }
  } catch (e) {}
}

export async function doDownload(url: string, filename: string) {
  const options = await getBaseOptions('GET', null);
  const res = await fetch(url, options);
  const blob = await res.blob();
  if (res.ok) {
    downloadFile(blob, filename);
  } else {
    errorToast();
  }
}

export function downloadFile(blob: Blob | string, filename?: string) {
  if (typeof blob === 'string') {
    blob = new Blob([blob]);
  }
  const file = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = file;
  anchor.target = '_blank';
  if (filename) {
    anchor.download = filename;
  }
  anchor.click();
}
