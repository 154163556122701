import { ChangeEvent, useState } from 'react';
import { differenceInSeconds, formatDistanceToNow } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';
import EditOrderForm from './EditOrderForm';
import Title from '../../../components/Title';
import Loading from '../../../components/Loading';
import OrderStateBadge from '../../../components/OrderStateBadge';
import TextInput from '../../../components/form/TextInput';
import {
  apiArchiveOrder,
  apiReorder,
  apiUpdateOrder,
  downloadLatestPurchaseOrder,
  downloadLatestQuote,
} from '../../../api/order-api';
import { RouteNames } from '@web/consts/routeNames.ts';
import dayjs, { Dayjs } from 'dayjs';
import { setDocumentTitle } from '../../Root';
import { OrderComments } from './OrderComments';
import OrderDocumentsDownload from './OrderDocumentsDownload';
import { errorToast, successToast } from '../../../common/toasts';
import { orderCacheKey, useOrder } from '../../../queries/orders';
import { useCurrentUser } from '../../../queries/users';
import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  IconButton,
  Stack,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { envVars, Vars } from '@web/common/environment.ts';
import OrderStateEventButtons from '../../../components/OrderStateEventButtons';
import Confirm from '../../../components/Confirm';
import { useQueryClient } from '@tanstack/react-query';
import { BiDownload } from 'react-icons/bi';
import { snakeCaseToSentenceCase } from '@web/common/lib/string-utils/string-utils';
import { Select } from 'chakra-react-select';
import { Actor, IGroupedOption, IOption } from '@senrasystems/senra-ui';
import { apiCreateQuote } from '../../../api/quote-api';
import { FaCashRegister } from 'react-icons/fa';
import { RiArchiveStackFill } from 'react-icons/ri';
import { MdChecklist } from 'react-icons/md';

export default function OrderDetails() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUser();
  const { orderId = '' } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [orderName, setOrderName] = useState('');
  const { data: order } = useOrder(orderId);
  const [isUpdatingSubscribers, setIsUpdatingSubscribers] = useBoolean();
  const isEmployee = user?.employee;

  const reloadOrder = () => queryClient.invalidateQueries({ queryKey: orderCacheKey(orderId) });

  const handleReorder = async () => {
    if (!order) return;
    const results = await apiReorder(order.id);
    if (results) {
      navigate(RouteNames.ORDERS.SHOW.replace(':orderId', results.id));
    }
  };

  const handleDiscardChanges = async () => {
    setIsEditing(false);
    await reloadOrder();
  };

  async function handleUpdateSubscribers(selected: IOption[]) {
    if (!order) return;
    setIsUpdatingSubscribers.on();
    const results = await apiUpdateOrder(order.id, {
      subscriberIds: selected.map((s: IOption) => s.value),
    });
    if (results) {
      if (selected.length === 0) {
        successToast('Removed all subscribers');
      } else {
        successToast('Subscribed ' + selected.map((s: IOption) => s.label).join(', ') + ' to notifications');
      }
      await reloadOrder();
    } else {
      errorToast('Error updating subscribers');
    }
    setIsUpdatingSubscribers.off();
  }

  const handleArchiveOrder = async () => {
    if (!order) return;
    await apiArchiveOrder(order.id);
    navigate(RouteNames.ORDERS.INDEX);
  };

  const openOrderInFactoryWeb = () => {
    if (!order) return;
    window.open(
      `${envVars.get(Vars.FactoryWebBaseUrl)}/orders/${order.id}/line_items/${order.lineItems[0].id}`,
      '_blank',
    );
  };

  const viewAllQuotesInFactoryWeb = () => {
    if (!order) return;
    window.open(`${envVars.get(Vars.FactoryWebBaseUrl)}/quotes?q=${order.orderNumber}`, '_blank');
  };

  const createQuoteAndOpenInFactoryWeb = async () => {
    if (!order) return;
    const quote = await apiCreateQuote({ orderId: order.id });
    window.open(`${envVars.get(Vars.FactoryWebBaseUrl)}/quotes/${quote.id}/line_items`, '_blank');
  };

  const validateFiles = (lineItems: { id: string; documents: [] }[]) =>
    lineItems.every((lineItem) => lineItem.documents.length > 0);

  async function onSubmit(formData: FieldValues) {
    if (!isEditing || !order?.id) return;

    if (!validateFiles(formData.order.lineItems)) {
      errorToast('Some documents are missing.');
      return;
    }

    const results = await apiUpdateOrder(order.id, {
      name: orderName,
      notes: formData.order.notes,
      materialAcquisition: formData.order.materialAcquisition,
      qualityRequirements: formData.order.qualityRequirements,
      salesWinProbability: formData.order.salesWinProbability,
      lineItems: formData.order.lineItems,
    });

    if (results) {
      successToast('Saved');
      setIsEditing(false);
      await reloadOrder();
    } else {
      errorToast('Error saving order');
    }
  }

  if (!order) {
    return <Loading message="Please wait while we load your order…" />;
  }
  if (orderName === '') {
    setOrderName(order.name);
  }
  setDocumentTitle([order.name]);

  const orderState = order.state;

  const title = isEditing ? (
    <Box>
      <TextInput
        value={orderName || ''}
        w={600}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setOrderName(e.target.value)}
      />
    </Box>
  ) : (
    <HStack spacing={4}>
      <Text color="primary">{order.name}</Text>
      <OrderStateBadge orderState={orderState} />
    </HStack>
  );

  const companyName = order.tenant.name;
  const quote = order.quote;
  const purchaseOrder = order.purchaseOrder;
  const orderPlacedBy = order.user.name;
  const orderCreatedAt = (dayjs(order.createdAt) as Dayjs).format('MMM D, YYYY h:mm A');
  const orderNumber = order.orderNumber;
  const isQuoteExpired = order.quote?.expiresAt
    ? differenceInSeconds(new Date(order.quote.expiresAt), Date.now()) < 0
    : false;

  const processedItems = order.lineItems.filter((item) => item.documentsProcessed).length;

  const Notifications = ({
    subscribers,
    subscribableUsers,
  }: {
    orderId: string;
    subscribers: Actor[];
    subscribableUsers: Actor[];
  }) => {
    const customers = subscribableUsers.filter((u) => !u.employee);
    const employees = subscribableUsers.filter((u) => u.employee);

    const options: IGroupedOption[] = [
      { label: '— CUSTOMERS —', options: customers.map((c) => ({ label: c.name, value: c.id })) },
      { label: '— EMPLOYEES —', options: employees.map((e) => ({ label: e.name, value: e.id })) },
    ];

    return (
      <HStack spacing={2}>
        <Text color="gray.400">Subscribers:</Text>
        <Tooltip label="Subscribed users will receive email notifications for updates to this order">
          <InfoIcon />
        </Tooltip>
        <Select
          size="sm"
          isMulti
          isLoading={isUpdatingSubscribers}
          isSearchable
          placeholder="Select subscribers..."
          value={subscribers.map((s) => ({ label: s.name, value: s.id }))}
          options={options}
          onChange={(selected) => handleUpdateSubscribers(selected as IOption[])}
        />
      </HStack>
    );
  };

  return (
    <Stack p={8} spacing={6}>
      <HStack justify="space-between">
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate(RouteNames.ORDERS.INDEX)}>Orders</BreadcrumbLink>
          </BreadcrumbItem>
          {isEmployee && (
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate(RouteNames.ORDERS.INDEX + `?q=${companyName}`)}>
                {companyName}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{orderNumber}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        {isEmployee && (
          <Notifications
            orderId={order.id}
            subscribers={order.subscribers}
            subscribableUsers={order.subscribableUsers}
          />
        )}
      </HStack>
      <Title title={title}>
        <HStack spacing={4}>
          {!isEditing && isEmployee && (
            <Confirm
              title="Are you sure you want to clone this order?"
              body="This will copy all line items, and set need by dates to one month from now. You should manually update the cloned order as needed."
              onConfirm={handleReorder}
            >
              <Button size="sm">Clone Order</Button>
            </Confirm>
          )}
          {!isEditing && (
            <>
              {(orderState === 'quote_expired' || orderState === 'declined') && (
                <Confirm
                  title="Are you sure you want to archive this order?"
                  body="It will no longer be visible in your list of orders."
                  onConfirm={handleArchiveOrder}
                >
                  <Button size="sm" color="red.500">
                    Archive Order
                  </Button>
                </Confirm>
              )}
              <OrderStateEventButtons order={order} navigate={navigate} reloadOrders={reloadOrder} />
              <Tooltip
                hasArrow
                placement="top"
                label={
                  order.lineItems.length === processedItems ? (
                    'Extraction Complete'
                  ) : (
                    <Box textAlign="center">
                      Ongoing Extraction
                      <br />
                      {processedItems} of {order.lineItems.length} completed
                    </Box>
                  )
                }
              >
                <Box>
                  <Icon
                    as={MdChecklist}
                    color={order.lineItems.length === processedItems ? 'green.500' : 'orange.500'}
                  />
                </Box>
              </Tooltip>
              {isEmployee && (
                <Button
                  size="sm"
                  color="green.400"
                  onClick={() => createQuoteAndOpenInFactoryWeb()}
                  leftIcon={<FaCashRegister />}
                >
                  Create New Quote
                </Button>
              )}
              {isEmployee && (
                <Button
                  size="sm"
                  color="green.400"
                  onClick={() => viewAllQuotesInFactoryWeb()}
                  leftIcon={<RiArchiveStackFill />}
                >
                  View Quotes
                </Button>
              )}
              {isEmployee && order.state === 'accepted' && (
                <Button
                  size="sm"
                  color="green.400"
                  onClick={() => openOrderInFactoryWeb()}
                  leftIcon={<ExternalLinkIcon />}
                >
                  Open in MES
                </Button>
              )}
            </>
          )}
          {isEmployee && !isEditing && (
            <Button size="sm" onClick={() => setIsEditing(true)}>
              Edit Order
            </Button>
          )}
        </HStack>
      </Title>

      {orderState == 'declined' && isEmployee && order.declineType && (
        <Alert>
          <AlertIcon />
          <VStack alignItems="left" spacing={0}>
            <Text>
              <strong>Decline Type:</strong> {snakeCaseToSentenceCase(order.declineType)}
            </Text>
            <Text>
              <strong>Notes:</strong> {order.declineNotes}
            </Text>
          </VStack>
        </Alert>
      )}

      {!quote && orderState === 'pending_quote' && (
        <Alert>
          <AlertIcon />
          Thank you for your order. We have received your order and will be in touch soon.
        </Alert>
      )}

      <StatGroup gap={20}>
        <Stat>
          <StatLabel>Order Number</StatLabel>
          <StatNumber>{orderNumber}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Company</StatLabel>
          <StatNumber>{companyName}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Order Placed By</StatLabel>
          <StatNumber>{orderPlacedBy}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Order Created</StatLabel>
          <StatNumber>{orderCreatedAt}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Order Amount</StatLabel>
          <StatNumber>{purchaseOrder?.purchaseOrderAmount ? '$' + purchaseOrder.purchaseOrderAmount : '-'}</StatNumber>
        </Stat>
        {!!quote && (
          <Stat>
            <StatLabel>Quote</StatLabel>
            <StatNumber>
              <HStack gap={4}>
                <Box>{quote.estimateNumber ?? '-'}</Box>
                <Tooltip label="Download Latest Quote" placement="top">
                  <IconButton
                    variant="outline"
                    size="sm"
                    aria-label="Download Latest Quote"
                    onClick={() => downloadLatestQuote(quote.id, orderNumber)}
                    icon={<BiDownload />}
                  />
                </Tooltip>
              </HStack>
            </StatNumber>
            {order.quote?.expiresAt && (
              <StatHelpText>
                {isQuoteExpired ? 'Expired ' : 'Valid for '}
                {formatDistanceToNow(new Date(order.quote.expiresAt))}
                {isQuoteExpired ? ' ago' : null}
              </StatHelpText>
            )}
          </Stat>
        )}
        {!!purchaseOrder && (
          <Stat>
            <StatLabel>Purchase Order</StatLabel>
            <StatNumber>
              <HStack gap={4}>
                <Box>{purchaseOrder.purchaseOrderNumber ?? '-'}</Box>
                <Tooltip label="Download Latest PO" placement="top">
                  <IconButton
                    variant="outline"
                    size="sm"
                    aria-label="Download Latest PO"
                    onClick={() => downloadLatestPurchaseOrder(purchaseOrder.id, orderNumber)}
                    icon={<BiDownload />}
                  />
                </Tooltip>
              </HStack>
            </StatNumber>
          </Stat>
        )}
      </StatGroup>
      <EditOrderForm order={order} readOnly={!isEditing} onSubmit={onSubmit} onCancelEdit={handleDiscardChanges} />
      <OrderDocumentsDownload order={order} />
      <Title title="Comments" />
      {isEmployee && !order.tenant.usBased && (
        <Text bg="secondary" px={1}>
          Customer is located outside of the U.S. Do not provide any ITAR-controlled technical information.
        </Text>
      )}
      <OrderComments orderId={order.id} />
    </Stack>
  );
}
