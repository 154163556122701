import { MouseEvent, ReactNode } from 'react';
import { BOMItem as Item, BOMItemUsage, Part } from '@senrasystems/senra-ui';
import { Box, HStack, Icon, StackProps, Text, TextProps } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import AddIconButton from '../../../components/AddIconButton.tsx';
import AddAlternateButton from './AddAlternateButton.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { usePartSearchModal } from '../../../hooks/usePartSearchModal.tsx';
import { useSelectedDesignPart } from '../../../hooks/useSelectedDesignPart.tsx';

interface Props extends StackProps {
  bomItem?: Item;
  isHeader?: boolean;
  isExpanded?: boolean;
  hasFocus?: boolean;
}

/**
 * BOMItem component displays a single row of the Bill of Materials.
 * @param bomItem
 * @param isHeader
 * @param isExpanded
 * @param hasFocus
 * @param rest
 * @constructor
 */
const BOMItem = ({ bomItem, isHeader, isExpanded, hasFocus = false, ...rest }: Props) => {
  const { isViewOnly } = useDesign();
  const selectedDesignPart = useSelectedDesignPart();
  const isSelected = bomItem?.partNumber === selectedDesignPart?.partData.partNumber;

  const { openModal } = usePartSearchModal();

  if (!isHeader && !bomItem) {
    throw new Error('BOMItem: bomItem is required, if header is false.');
  }

  const styles = {
    color: isHeader ? 'gray.600' : 'gray.500',
    fontWeight: isHeader ? '500' : 'normal',
    borderColor: 'gray.200',
    bg: isExpanded ? 'blue.100' : 'transparent',
  };

  // Render the action button for search result from the part search modal when adding an alternate part.
  const searchResultActionRenderFn = (part: Part) => {
    if (bomItem) {
      // If the part is already an alternate, don't show the add alternate button
      if (bomItem.alternates.some((alternate) => alternate.alternatePart.id === part.id)) return;
      // Show the add alternate button
      return <AddAlternateButton part={part} preferredPart={bomItem.part} />;
    }
  };

  // Convert an array of parts to a string of part names.
  const usagesToString = (usages: BOMItemUsage[] | undefined) => {
    return usages ? usages.map((p) => p.name).join(', ') : '';
  };

  // Handle the close event of the modal.
  const handleAddAlternateClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isSelected) {
      event.stopPropagation();
    }
    openModal({
      initialPartTypeFilters: bomItem?.type ? [bomItem.type] : [],
      filtersDisabled: true,
      renderSearchResultAction: searchResultActionRenderFn,
    });
  };

  // Render Text with label or value.
  const renderText = (label: string, value: number | string | undefined | ReactNode, textProps: TextProps) => {
    return (
      <Text as={Box} isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" {...textProps}>
        {isHeader ? label : value}
      </Text>
    );
  };

  // Render the selection indicator, a blue bar on the left side of the row.
  const renderSelectionIndicator = () => {
    return <Box w="5px" h="24px" bg={isSelected ? 'blue.200' : 'transparent'} />;
  };

  return (
    <HStack w="full" p={1} gap={4} role="group" aria-expanded={isExpanded} {...styles} {...rest}>
      {renderSelectionIndicator()}
      <Icon
        as={isExpanded ? ChevronDownIcon : ChevronRightIcon}
        mb={1}
        mr={1}
        visibility={isHeader || !hasFocus ? 'hidden' : 'visible'}
      />
      {renderText('ID', bomItem?.itemNumber, { flex: 1 })}
      {renderText('Type', bomItem?.displayType, { flex: 2 })}
      {renderText('Qty', bomItem?.displayQuantity, { flex: 1, textAlign: 'center' })}
      {renderText('Unit', bomItem?.unit, { flex: 1, textAlign: 'center' })}
      {renderText('Part Number', bomItem?.partNumber, { flex: 2 })}
      {renderText('Usage', usagesToString(bomItem?.usages), { flex: 4 })}
      {renderText('Description', bomItem?.description, { flex: 8 })}
      {renderText('Manufacturers', bomItem?.manufacturer, { flex: 4 })}
      {renderText('Alts', bomItem?.alternates.length, { flex: 1, textAlign: 'center' })}
      {!isViewOnly && (
        <AddIconButton
          aria-label={'Add alternate part'}
          variant="secondary"
          visibility="hidden"
          _groupHover={{ visibility: !isHeader && hasFocus ? 'visible' : 'hidden' }}
          onClick={handleAddAlternateClick}
        />
      )}
    </HStack>
  );
};

export default BOMItem;
