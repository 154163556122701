import { Operations } from '../Operations.ts';
import { isBreakoutPointNode, isMeasurementEdge, isSegmentEdge } from '../../types.ts';
import { getConnectedMeasurements, getConnectedSegments, getMeasurementEdgePath } from '../../utils/graph.ts';
import { createAndAddBreakoutPointNode, findNodeById } from '../NodeFactory.ts';
import { createAndAddMeasurementEdge, createAndAddSegmentEdge, findEdge, removeEdgeIfExists } from '../EdgeFactory.ts';
import { findBundle, removeBundleFromSegment } from '../../utils/bundles.ts';
import { UUID } from '@senrasystems/senra-ui';
import { SegmentEdgeData } from '../../components/edges/SegmentEdge/SegmentEdge.tsx';
import { Edge } from '@xyflow/react';
import { Graph } from '../../../../../types/reactFlow.ts';

// Operation to unmerge a bundle into individual segments
export type UnmergeBundleOperation = {
  type: 'UnmergeBundle';
  params: {
    breakoutPointId: UUID;
    bundleId: string;
  };
};

/**
 * Unmerges a bundle into individual segments.
 */
export class UnmergeBundle implements Operations<UnmergeBundleOperation> {
  // Execute the operation
  execute(graph: Graph, operation: UnmergeBundleOperation): Graph {
    const { nodes, edges } = graph;
    const { breakoutPointId, bundleId } = operation.params;

    // Step 1: Find the breakout point node
    const breakoutPointNode = findNodeById(nodes, breakoutPointId, isBreakoutPointNode);

    if (!breakoutPointNode) {
      // eslint-disable-next-line no-console
      console.warn(`Breakout point node not found: ${breakoutPointId}`);
      return graph;
    }

    // Step 2: Find connected segments
    const connectedSegments = getConnectedSegments(edges, breakoutPointNode.id, bundleId);

    if (connectedSegments.length === 0) {
      // eslint-disable-next-line no-console
      console.warn(`No connected segments found for breakout point node: ${breakoutPointId}`);
      return graph;
    }

    // Step 3: Create a new breakout point node
    const newBreakoutPointNode = createAndAddBreakoutPointNode(nodes, {
      x: breakoutPointNode.position.x,
      y: breakoutPointNode.position.y + 50,
    });

    if (!newBreakoutPointNode) {
      // eslint-disable-next-line no-console
      console.warn('Failed to create a new breakout point node.');
      return graph;
    }

    // Step 4: For each segment, create a new segment with the new breakout point node, and transfer the bundle
    connectedSegments.forEach((edge: Edge<SegmentEdgeData>) => {
      // Skip edges without data, this should not happen
      if (!edge.data) {
        return;
      }

      // Find the bundle to split out to the new breakout point node
      const bundle = findBundle(edge.data.bundles, bundleId);

      if (bundle) {
        // Create a new measurement and segment edge with the new breakout point node, and transfer the bundle
        const otherNode = edge.source === breakoutPointNode.id ? edge.target : edge.source;
        const originalMeasurementEdge = findEdge(edges, edge.source, edge.target, isMeasurementEdge);

        if (originalMeasurementEdge) {
          const newMeasurementEdge = createAndAddMeasurementEdge(edges, newBreakoutPointNode.id, otherNode, {
            ...originalMeasurementEdge.data,
          });
          createAndAddSegmentEdge(edges, newBreakoutPointNode.id, otherNode, {
            ...edge.data,
            measurementSource: newMeasurementEdge?.source,
            measurementTarget: newMeasurementEdge?.target,
            bundles: [bundle],
          });
          if (!bundle.sourceDesignPartId || !bundle.destinationDesignPartId) {
            newBreakoutPointNode.data.isTerminal = true;
          }
        }

        // Remove the bundle from the original segment
        removeBundleFromSegment(edges, edge.id, bundleId);
      }
    });

    // Re-evaluate if the original breakout point is still terminal
    breakoutPointNode.data.isTerminal = false;

    connectedSegments.forEach((edge) => {
      if (edge && isSegmentEdge(edge)) {
        edge.data.bundles.forEach((bundle) => {
          if (!bundle.sourceDesignPartId || !bundle.destinationDesignPartId) {
            breakoutPointNode.data.isTerminal = true;
          }
        });
      }
    });

    // Step 5: Cleanup measurements
    const connectedMeasurements = getConnectedMeasurements(edges, breakoutPointNode.id);
    connectedMeasurements.forEach((edge) => {
      const { pathExists } = getMeasurementEdgePath(nodes, edges, edge.source, edge.target);
      if (!pathExists) {
        removeEdgeIfExists(edges, edge.id);
      }
    });

    return { nodes, edges };
  }
}
