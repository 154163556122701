import { Edge, Node } from '@xyflow/react';
import { ConnectionPoint, DesignPart, UUID } from '@senrasystems/senra-ui';
import { NodeType } from '../types/nodes.ts';
import { EdgeType } from '../types/edges.ts';
import { ConnectorPosition } from '../components/nodes/ConnectorNode.tsx';
import { HandleTypes } from '../types/handles.ts';
import { defaultPosition } from '../../../../types/reactFlow.ts';

export const createConnectorNode = (
  designPart: DesignPart | null,
  connectorPosition: ConnectorPosition = ConnectorPosition.Left,
): Node => {
  return {
    id: designPart ? designPart.id : window.crypto.randomUUID(),
    type: NodeType.Connector,
    data: { designPartId: designPart ? designPart.id : null, position: connectorPosition },
    position: defaultPosition,
  };
};

export const createCavityNode = (connectionPoint: ConnectionPoint | null, connectorNodeId: UUID): Node => {
  return {
    id: connectionPoint ? connectionPoint.id : window.crypto.randomUUID(),
    parentId: connectorNodeId,
    type: NodeType.Cavity,
    data: { connectionPointId: connectionPoint ? connectionPoint.id : null },
    position: defaultPosition,
    draggable: false,
  };
};

export const createConductorEdge = (source: UUID, target: UUID): Edge => {
  return {
    id: window.crypto.randomUUID(),
    type: EdgeType.Conductor,
    source,
    sourceHandle: HandleTypes.Source,
    target,
    targetHandle: HandleTypes.Target,
  };
};
