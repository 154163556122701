import { Edge, Node } from '@xyflow/react';
import { Graph } from '../../../../types/reactFlow.ts';
import { ConnectionGroupMap } from '../hooks/useSchematicData.tsx';
import { createCavityNode, createConductorEdge, createConnectorNode } from './factory.ts';
import { ConnectorPosition } from '../components/nodes/ConnectorNode.tsx';

export const buildGraph = (connectionGroupMap: ConnectionGroupMap): Graph => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  Object.entries(connectionGroupMap).map(([, { connectionGroup }]) => {
    const sourceConnectorNode = createConnectorNode(connectionGroup.source?.designPart || null, ConnectorPosition.Left);
    nodes.push(sourceConnectorNode);

    const destinationConnectorNode = createConnectorNode(
      connectionGroup.destination?.designPart || null,
      ConnectorPosition.Right,
    );
    nodes.push(destinationConnectorNode);

    connectionGroup.connections.map((connection) => {
      const sourceCavityNode = createCavityNode(connection.source, sourceConnectorNode.id);
      nodes.push(sourceCavityNode);
      const destinationCavityNode = createCavityNode(connection.destination, destinationConnectorNode.id);
      nodes.push(destinationCavityNode);
      edges.push(createConductorEdge(sourceCavityNode.id, destinationCavityNode.id));
    });
  });

  return { nodes: nodes, edges: edges };
};
