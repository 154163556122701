import { Box, Button, ButtonProps, HStack } from '@chakra-ui/react';
import { IoSparklesSharp } from 'react-icons/io5';

interface Props extends ButtonProps {
  partNumber: string;
}

const PartSearchAIButton = ({ partNumber, ...rest }: Props) => {
  const handleAISearch = () => {
    if (partNumber) {
      window.open(`http://aiserver:8543/search?q=${encodeURIComponent(partNumber)}`, '_blank');
    }
  };

  return (
    <Button
      onClick={handleAISearch}
      isDisabled={!partNumber}
      bg="gray.600"
      _hover={{ bg: 'gray.700' }}
      _active={{ bg: 'gray.800' }}
      color="white"
      {...rest}
    >
      <HStack spacing="4px">
        <IoSparklesSharp size={16} style={{ strokeWidth: 2.5 }} />
        <Box fontSize="xl" fontWeight="medium">
          AI
        </Box>
      </HStack>
    </Button>
  );
};

export default PartSearchAIButton;
