import { Edge, Node } from '@xyflow/react';
import { Operations } from '../Operations';
import { Graph } from '../../../../../types/reactFlow.ts';

export type AddElementsToGraphOperation = {
  type: 'AddElementsToGraph';
  params: {
    newNodes?: Node[];
    newEdges?: Edge[];
  };
};

/**
 * Adds new nodes and edges to the graph.
 */
export class AddElementsToGraph implements Operations<AddElementsToGraphOperation> {
  // Execute the operation
  execute(graph: Graph, operation: AddElementsToGraphOperation): Graph {
    const { nodes, edges } = graph;
    const { newNodes, newEdges } = operation.params;

    const updatedNodes = newNodes && newNodes.length > 0 ? [...nodes, ...newNodes] : nodes;
    const updatedEdges = newEdges && newEdges.length > 0 ? [...edges, ...newEdges] : edges;

    return { nodes: updatedNodes, edges: updatedEdges };
  }
}
