import { Tooltip } from '@chakra-ui/react';
import { BOMItem } from '@senrasystems/senra-ui';
import { NumberCircle } from '../../notes/NumberCircle';
import FieldValueList from '../../../../../components/FieldValueList';

interface Props {
  bomItem: BOMItem;
  disableTooltip: boolean;
}

export const LayoutBomNote = ({ bomItem, disableTooltip }: Props) => {
  const { itemNumber, displayType, partNumber, description, manufacturer } = bomItem;

  const bomItemData = [
    { field: 'Type', value: displayType },
    { field: 'Part Number', value: partNumber },
    { field: 'Description', value: description },
    { field: 'Manufacturer', value: manufacturer },
  ];

  return (
    <Tooltip
      label={<FieldValueList data={bomItemData} fieldProps={{ minW: 28 }} valueProps={{ minW: 40 }} />}
      isDisabled={disableTooltip}
    >
      <NumberCircle number={itemNumber} />
    </Tooltip>
  );
};
