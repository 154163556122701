import { Box, BoxProps, HStack, IconButtonProps, Input, InputProps } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { isAccessory, Part, PartStatus } from '@senrasystems/senra-ui';
import { useDesignId } from '../../../hooks/useDesignId.tsx';
import { useSuggestPartName } from '../../../hooks/useSuggestPartName.tsx';
import { useCreateDesignPartMutation } from '../../../api/queries.ts';
import AddIconButton from '../../../components/AddIconButton.tsx';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';

interface Props extends BoxProps {
  part: Part;
  inputProps?: InputProps;
  buttonProps?: IconButtonProps;
  visibleIfDraftPart?: boolean;
}

/**
 * AddPartButton component is a button that allows the user to add a part to the design. It includes an input field for
 * the part name and an icon button to add the part to the design. The part name is automatically suggested based on the
 * part type. If the user changes the suggested name, the component will not suggest a new name until the user does a
 * new search. If we're adding a part is an accessory, we don't show the input field for the part name. The button is
 * only visible if the part is released.
 * @param part
 * @param inputProps
 * @param buttonProps
 * @param visibleIfDraftPart
 * @param rest
 * @constructor
 */
const AddPartButton = ({ part, inputProps, buttonProps, visibleIfDraftPart = false, ...rest }: Props) => {
  const designId = useDesignId();
  const { showSuccessToast } = useDesignToast();
  const { suggestPartName, isLoading, error } = useSuggestPartName(designId);
  const [partName, setPartName] = useState('');
  const [suggestedNameHasChanged, setSuggestedNameHasChanged] = useState(false);
  const visibility = part.status === PartStatus.DRAFT ? (visibleIfDraftPart ? 'visible' : 'hidden') : 'visible';

  // Suggest a part name based on the part type
  useEffect(() => {
    if (part && !isLoading && !error && !suggestedNameHasChanged) {
      setPartName(suggestPartName(part));
      setSuggestedNameHasChanged(false);
    }
  }, [error, isLoading, part, partName, suggestPartName, suggestedNameHasChanged]);

  // Handle mutation adding a part
  const handlePartCreateSuccess = () => {
    setPartName('');
    setSuggestedNameHasChanged(false);
    showSuccessToast('Part Added', 'The part was successfully added to the design.');
  };
  const { mutate: addDesignPart, isPending: addDesignPartPending } =
    useCreateDesignPartMutation(handlePartCreateSuccess);

  if (!part || isLoading || error) {
    return;
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPartName(event.target.value);
    setSuggestedNameHasChanged(true);
  };

  const handleAddClick = () => {
    addDesignPart({
      designId,
      data: {
        name: partName,
        partId: part.id,
      },
    });
  };

  return (
    <Box visibility={visibility} {...rest}>
      <HStack>
        {!isAccessory(part.type) && (
          <Input
            id="part-name"
            flex={1}
            value={partName}
            onChange={handleOnChange}
            placeholder="Name"
            borderRadius="md"
            _focus={{
              border: 'none',
              boxShadow: 'none',
            }}
            autoComplete="off"
            {...inputProps}
          />
        )}
        <AddIconButton
          aria-label="Add"
          onClick={handleAddClick}
          bg="gray.300"
          _hover={{ color: 'white', bg: 'blue.500' }}
          isLoading={addDesignPartPending || !partName || partName.length === 0}
          {...buttonProps}
        />
      </HStack>
    </Box>
  );
};

export default AddPartButton;
