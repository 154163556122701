import { Text } from '@chakra-ui/react';
import { PartGroup } from '@senrasystems/senra-ui';
import Title from '@web/components/Title.tsx';
import CountBadge from '../../../../components/CountBadge';
import AddIconButton from '../../../../components/AddIconButton';

interface Props {
  partGroup: PartGroup;
  showAddButton: boolean;
  onAddClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hasFocus: boolean;
}

export const PartGroupTitle = ({ partGroup, onAddClick, hasFocus }: Props) => (
  <Title
    as="h4"
    title={
      <Text>
        {partGroup.name}
        <CountBadge count={partGroup.parts.length} />
      </Text>
    }
    size="sm"
  >
    {onAddClick && (
      <AddIconButton
        aria-label={`Add ${partGroup.name}`}
        variant="secondary"
        onClick={onAddClick}
        opacity={hasFocus ? 1 : 0}
        transition="opacity .3s ease-in-out"
      />
    )}
  </Title>
);
