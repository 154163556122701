import { useState } from 'react';
import { Text, Textarea, TextareaProps } from '@chakra-ui/react';

interface Props extends TextareaProps {
  text: string;
  onEdit: (value: string) => void;
  editAction?: 'dblclick' | 'click';
}

const EditableTextarea = ({ text, onEdit, editAction = 'dblclick', ...rest }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);

  const startEditing = () => {
    setValue(text);
    setIsEditing(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleOnBlur = () => {
    setIsEditing(false);
    const trimmedValue = value.trim();
    if (trimmedValue && trimmedValue !== text) {
      onEdit(trimmedValue);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleOnBlur();
    }
  };

  return isEditing ? (
    <Textarea
      value={value}
      onChange={handleChange}
      onBlur={handleOnBlur}
      onKeyDown={handleOnKeyDown}
      autoFocus
      {...rest}
    />
  ) : (
    <Text
      w="full"
      onClick={editAction === 'click' ? startEditing : undefined}
      onDoubleClick={editAction === 'dblclick' ? startEditing : undefined}
      whiteSpace="pre-wrap"
    >
      {text}
    </Text>
  );
};

export default EditableTextarea;
