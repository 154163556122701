import { useEffect } from 'react';
import { Node, NodeProps, useUpdateNodeInternals } from '@xyflow/react';
import { Box } from '@chakra-ui/react';
import { UUID } from '@senrasystems/senra-ui';
import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { RotatingHandle } from '../common/RotatingHandle.tsx';
import { CenterHandle } from '../common/CenterHandle.tsx';
import { DesignPartLabel } from '../common/DesignPartLabel.tsx';
import { originalWidth, PigtailShape } from './PigtailShape.tsx';
import { getNodeColor } from '../../../utils/common.ts';
import { useShapeRotation } from '../common/useShapeRotation.tsx';
import { CenterLock } from '../common/CenterLock.tsx';
import { useDesignOverview } from '../../../../../hooks/useDesignOverview.tsx';
import { HandleTypes } from '../../../types/handles.ts';

export type PigtailNodeData = {
  designPartId: UUID;
  rotateLock: boolean;
  angle: number;
};

export const defaultPigtailNodeData: PigtailNodeData = {
  designPartId: '',
  rotateLock: false,
  angle: 0,
};

export type PigtailNode = Node<PigtailNodeData>;

const distanceFromCenter = originalWidth / 2;

/**
 * Connector node component.
 * @param props
 * @constructor
 */
export const PigtailNode = (props: NodeProps<PigtailNode>) => {
  const { id, data = defaultPigtailNodeData, selected } = props;
  const { designPartId, rotateLock, angle } = data;
  const designPart = useDesignParts().getDesignPartById(designPartId);
  const { measurementMode } = useDesignOverview();

  // ReactFlow hook to update the node internals, because the handle is rotated and ReactFlow needs to know about it
  const updateNodeInternals = useUpdateNodeInternals();

  // Calculate the angle to rotate the shape
  const calculatedAngle = useShapeRotation({ nodeId: id });

  // Use the calculated angle if the rotate lock is not enabled
  const resolvedAngle = rotateLock ? angle : calculatedAngle;

  useEffect(() => {
    updateNodeInternals(id);
  }, [resolvedAngle, id, updateNodeInternals]);

  if (!designPart) {
    return null;
  }

  return (
    <Box position="relative">
      <DesignPartLabel designPart={designPart} angle={resolvedAngle} />
      <PigtailShape color={getNodeColor(selected)} angle={resolvedAngle} />
      <RotatingHandle
        id={HandleTypes.Segment}
        angle={resolvedAngle}
        offsetPosition={{ x: -distanceFromCenter, y: 0 }}
      />
      <RotatingHandle
        id={HandleTypes.Measurement}
        angle={resolvedAngle}
        offsetPosition={{ x: measurementMode === 'Face' ? distanceFromCenter : -distanceFromCenter, y: 0 }}
      />
      <CenterHandle id={HandleTypes.Note} />
      {rotateLock && <CenterLock />}
    </Box>
  );
};
