/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useReactFlow } from '@xyflow/react';
import { NoteType } from '@senrasystems/senra-ui';
import { useLayoutData } from '../../../hooks/useLayoutData';
import { useDesignBuildNotes } from '../../../hooks/useDesignBuildNotes';
import { useConnections } from '../../../hooks/useConnections';
import { getUpdatedGraph } from '../utils/updateGraph';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';
import { defaultViewport, Graph, setEdgesType, setNodesType } from '../../../../types/reactFlow.ts';

export const useLoadLayout = (setNodes: setNodesType, setEdges: setEdgesType): boolean => {
  const [layoutLoaded, setLayoutLoaded] = useState(false);
  const { setViewport } = useReactFlow();

  const { layoutData, isSuccess: isLayoutDataLoaded } = useLayoutData();
  const { connections } = useConnections();
  const { designParts } = useDesignParts();
  const { data: flagNotes } = useDesignBuildNotes(NoteType.FLAG);

  // Initialize layout
  useEffect(() => {
    const loadLayout = async () => {
      if (layoutLoaded) {
        return;
      }

      // Wait for data to load before trying to set nodes and edges
      if (isLayoutDataLoaded && connections && designParts && flagNotes) {
        console.time('Load Layout');

        let updatedGraph: Graph = { nodes: [], edges: [] };

        try {
          const { nodes = [], edges = [], viewport } = layoutData;
          if (viewport) {
            console.debug('Loading viewport from design.layoutData', layoutData);
            await setViewport({ ...defaultViewport, ...viewport });
          } else {
            console.debug('No existing viewport found.');
            await setViewport(defaultViewport);
          }

          updatedGraph = getUpdatedGraph({ nodes, edges, connections, designParts, flagNotes });
        } catch (error) {
          console.error('Failed to parse layout:', error);
          await setViewport(defaultViewport);
        } finally {
          console.timeEnd('Load Layout');
        }

        setNodes(updatedGraph.nodes);
        setEdges(updatedGraph.edges);
        setLayoutLoaded(true);
      }
    };

    void loadLayout();
  }, [
    setNodes,
    setEdges,
    layoutData,
    isLayoutDataLoaded,
    layoutLoaded,
    flagNotes,
    connections,
    setViewport,
    designParts,
  ]);

  return layoutLoaded;
};
