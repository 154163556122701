import { Stack, Alert, AlertIcon, Kbd } from '@chakra-ui/react';
import { NoteType, UUID } from '@senrasystems/senra-ui';
import NotesTable from './NotesTable';
import {
  useCreateBuildNoteMutation,
  useDeleteBuildNoteMutation,
  useUpdateBuildNoteMutation,
} from '../../../../api/queries';
import { useDesignId } from '../../../../hooks/useDesignId';
import { useDesignBuildNotes } from '../../../../hooks/useDesignBuildNotes';

const BuildNotesView = () => {
  const designId = useDesignId();
  const { data: buildNotes } = useDesignBuildNotes();

  const { mutate: createNoteMutation } = useCreateBuildNoteMutation();
  const { mutate: updateNoteMutation } = useUpdateBuildNoteMutation();
  const { mutate: deleteNoteMutation } = useDeleteBuildNoteMutation();

  const createNote = (body: string, type: NoteType) => createNoteMutation({ designId, body, type });
  const updateNotePosition = (noteId: UUID, position: string) =>
    updateNoteMutation({ designId, noteId, buildNote: { position: Number(position) } });
  const updateNoteBody = (noteId: UUID, body: string) => updateNoteMutation({ designId, noteId, buildNote: { body } });
  const deleteNote = (noteId: UUID) => deleteNoteMutation({ designId, noteId });

  return (
    <Stack h="40vh" gap={0}>
      <Alert status="info">
        <AlertIcon />
        Press <Kbd mx={2}>Enter</Kbd> to save your note and <Kbd mx={2}>Shift + Enter</Kbd> for a newline. Double-click
        a note to edit it.
      </Alert>
      <NotesTable
        notes={buildNotes}
        onAddNote={createNote}
        onEditNotePosition={updateNotePosition}
        onEditNoteBody={updateNoteBody}
        onDeleteNote={deleteNote}
      />
    </Stack>
  );
};

export default BuildNotesView;
