import { useState, useCallback, SetStateAction } from 'react';

export const useStateWithDirtyFlag = <T>(initialState: T) => {
  const [state, setState] = useState(initialState);
  const [isDirty, setIsDirty] = useState(false);

  const setStateWithDirtyFlag = useCallback((setStateAction: SetStateAction<T>) => {
    setIsDirty(true);
    setState(setStateAction);
  }, []);

  const resetState = useCallback(() => {
    setState(initialState);
    setIsDirty(false);
  }, [initialState]);

  return { state, isDirty, setStateWithDirtyFlag, resetState };
};
