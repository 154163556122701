import { useState } from 'react';
import { Part, PartType } from '@senrasystems/senra-ui';
import { usePartsByPartNumberQuery } from '../api/queries.ts';
import useDebouncedValue from '../../../hooks/useDebouncedValue.ts';

interface Params {
  searchTerm: string;
  designToolSearch: boolean;
  initialFilters: PartType[];
}

const allPartTypes = Object.values(PartType);
const emptyParts = [] as Part[];

export const usePartSearch = ({ searchTerm, designToolSearch = false, initialFilters = allPartTypes }: Params) => {
  const querySearchTerm = useDebouncedValue(searchTerm, 500);
  const [filters, setFilters] = useState<PartType[]>(initialFilters);
  const { data, isLoading, isSuccess, error } = usePartsByPartNumberQuery(querySearchTerm, filters, designToolSearch);
  const parts = data?.data || emptyParts;

  return { parts, filters, setFilters, isLoading, isSuccess, error };
};
