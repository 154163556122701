import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, FormLabel, HStack, Stack } from '@chakra-ui/react';
import DocumentDisplay from '@web/components/DocumentDisplay';
import { DocumentCategory, Part } from '@senrasystems/senra-ui';

interface Props {
  editing?: boolean;
}

const PartSpecReferences = ({ editing = true }: Props) => {
  const { control } = useFormContext<Part>();
  const {
    fields: documents,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'documents',
  });

  const handleAddDocument = () => {
    append({ id: '', type: DocumentCategory.Datasheet, url: '', filename: '', s3Key: '' });
  };

  return (
    <>
      <FormLabel id="referencesLabel" color={editing ? undefined : 'gray.500'}>
        References{editing ? '' : ':'}
      </FormLabel>
      <HStack>
        <Stack spacing={1}>
          {documents.map((document, index) => {
            return (
              <DocumentDisplay
                key={document.id}
                editing={editing}
                remove={remove}
                index={index}
                aria-labelledby="referencesLabel"
              />
            );
          })}
        </Stack>
      </HStack>
      <Button mt={2} visibility={editing ? 'initial' : 'hidden'} onClick={handleAddDocument}>
        Add Document
      </Button>
    </>
  );
};

export default PartSpecReferences;
