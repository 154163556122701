import { BoxProps, useBoolean, Box } from '@chakra-ui/react';
import ManagedWindow from '../../components/ManagedWindow.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import { CollapseButton } from './components/CollpseButton.tsx';
import { AssemblyNavigator } from './AssemblyNavigator.tsx';

export const AssemblyNavigatorWindow = (props: BoxProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useBoolean(true);

  return (
    <ManagedWindow
      title="Assembly Navigator"
      routeName={RouteNames.DESIGNS.DESIGN_PARTS}
      showTitle={false}
      position="relative"
      overflow="unset"
      bg="gray.50"
      {...props}
    >
      {({ hasFocus, windowIcon, isInLayout }) => (
        <>
          <AssemblyNavigator
            hasFocus={hasFocus}
            windowIcon={windowIcon}
            width={isInLayout ? 'md' : 'unset'}
            display={isSidebarOpen ? 'unset' : 'none'}
          />
          <Box
            flex={1}
            width={6}
            onClick={setIsSidebarOpen.on}
            cursor="pointer"
            bg="gray.50"
            _hover={{ bgColor: 'whiteAlpha.500' }}
            display={isSidebarOpen ? 'none' : 'unset'}
          />
          {isInLayout && <CollapseButton onClick={setIsSidebarOpen.toggle} isOpen={isSidebarOpen} />}
        </>
      )}
    </ManagedWindow>
  );
};
