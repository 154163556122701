import ToolsLibraryDashboard, { CustomColumnDef } from '../../components/ToolsLibraryDashboard.tsx';
import { Tool, Positioner, ToolType } from '@senrasystems/senra-ui';
import PositionerFields, { emptySetting } from './PositionerFields.tsx';
import { Text } from '@chakra-ui/react';

const defaultValues = { settings: [emptySetting] };

const columns: CustomColumnDef[] = [
  {
    header: 'Name',
    renderFn: (tool: Tool) => {
      const positioner = tool as Positioner;
      return <Text>{positioner.name}</Text>;
    },
  },
];

const PositionersDashboard = () => (
  <ToolsLibraryDashboard
    additionalFieldsComponent={PositionerFields}
    buttonText="Positioner"
    columnDefs={columns}
    defaultValues={defaultValues}
    routeName="positioners"
    title="Positioners"
    toolType={ToolType.POSITIONER}
  />
);

export default PositionersDashboard;
