import { Handle, Node, Position } from '@xyflow/react';
import { Box } from '@chakra-ui/react';
import { UUID } from '@senrasystems/senra-ui';

export type SpliceNodeData = {
  designPartId: UUID | null;
};

export const defaultSpliceNodeData: SpliceNodeData = {
  designPartId: null,
};

export type SpliceNode = Node<SpliceNodeData>;

/**
 * Splice node component.
 * @constructor
 */
export const SpliceNode = () => {
  return (
    <Box position="relative">
      <Box borderRadius="md" />
      <Handle type="source" position={Position.Right} />
      <Handle type="source" position={Position.Left} />
    </Box>
  );
};
