import { ShapeProps } from '../../../types/shapes.ts';

export const DiodeShape = ({ color }: ShapeProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 11.8301V15.0801C15.5 15.4943 15.1642 15.8301 14.75 15.8301C14.3358 15.8301 14 15.4943 14 15.0801V12.3792L7.625 16.0598C7.39295 16.1937 7.10705 16.1937 6.875 16.0598C6.64295 15.9258 6.5 15.6782 6.5 15.4103V11.8301H2.75C2.33579 11.8301 2 11.4943 2 11.0801C2 10.6659 2.33579 10.3301 2.75 10.3301H6.5V6.75C6.5 6.48205 6.64295 6.23446 6.875 6.10048C7.10705 5.96651 7.39295 5.96651 7.625 6.10048L14 9.78109V7.08008C14 6.66586 14.3358 6.33008 14.75 6.33008C15.1642 6.33008 15.5 6.66586 15.5 7.08008V10.3301H19.75C20.1642 10.3301 20.5 10.6659 20.5 11.0801C20.5 11.4943 20.1642 11.8301 19.75 11.8301H15.5ZM8 8.04904L13.25 11.0801L8 14.1112L8 8.04904Z"
        fill="white"
      />
    </svg>
  );
};
