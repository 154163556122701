import { Flex, useDisclosure } from '@chakra-ui/react';
import { ContextPanelWithTabs } from '../../components/ContextPanel';
import Layout from './Layout';
import BuildNotesView from './components/notes/BuildNotesView';
import { DetailsView } from './components/details/DetailsView.tsx';
import WiringList from '../WiringList/WiringList';
import { ReactFlowProvider } from '@xyflow/react';

export enum LayoutDrawer {
  BuildNotes = 'buildNotes',
  Details = 'details',
  Wiring = 'wiring',
}

const tabs = [
  { value: LayoutDrawer.BuildNotes, headerLabel: 'Build Notes', content: <BuildNotesView /> },
  {
    value: LayoutDrawer.Details,
    headerLabel: 'Details',
    content: <DetailsView />,
  },
  {
    value: LayoutDrawer.Wiring,
    headerLabel: 'Wiring List',
    content: <WiringList />,
  },
];

const LayoutWithDrawer = () => {
  const { isOpen, onToggle, onOpen } = useDisclosure();

  return (
    <Flex flexDirection="column" flex={1}>
      <ReactFlowProvider>
        <Layout />
        <ContextPanelWithTabs isOpen={isOpen} togglePanel={onToggle} openPanel={onOpen} tabs={tabs} />
      </ReactFlowProvider>
    </Flex>
  );
};

export default LayoutWithDrawer;
