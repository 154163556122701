import ToolsLibraryDashboard, { CustomColumnDef } from '../../components/ToolsLibraryDashboard.tsx';
import { Tool, TurretHead, ToolType } from '@senrasystems/senra-ui';
import TurretHeadFields, { emptySetting } from './TurretHeadFields.tsx';
import { Text } from '@chakra-ui/react';

const defaultValues = { settings: [emptySetting] };

const columns: CustomColumnDef[] = [
  {
    header: 'Name',
    renderFn: (tool: Tool) => {
      const turretHead = tool as TurretHead;
      return <Text>{turretHead.name}</Text>;
    },
  },
];

const TurretHeadsDashboard = () => (
  <ToolsLibraryDashboard
    additionalFieldsComponent={TurretHeadFields}
    buttonText="Turret Head"
    columnDefs={columns}
    defaultValues={defaultValues}
    routeName="turret-heads"
    title="Turret Heads"
    toolType={ToolType.TURRETHEAD}
  />
);

export default TurretHeadsDashboard;
