/* eslint-disable no-console */
import { useEffect, useMemo, useState } from 'react';
import { useReactFlow } from '@xyflow/react';
import { isConnectionTarget } from '@senrasystems/senra-ui';
import { defaultViewport, setEdgesType, setNodesType } from '../../../../types/reactFlow.ts';
import { useSelectedDesignPart } from '../../../hooks/useSelectedDesignPart.tsx';
import { ConnectionGroupMap, useSchematicData } from '../hooks/useSchematicData.tsx';
import { buildGraph } from '../utils/buildGraph.ts';

export const useLoadSchematic = (setNodes: setNodesType, setEdges: setEdgesType): boolean => {
  const [schematicLoaded, setSchematicLoaded] = useState(false);
  const { setViewport } = useReactFlow();

  const selectedDesignPart = useSelectedDesignPart();
  const { connectionGroupMap, isSuccess: isSchematicDataLoaded } = useSchematicData();

  // Filter schematic data to only the groups where the source is the selected design part
  const selectedDesignPartConnectionGroupMap: ConnectionGroupMap = useMemo(() => {
    if (selectedDesignPart && isConnectionTarget(selectedDesignPart)) {
      return Object.entries(connectionGroupMap).reduce((map, [key, value]) => {
        if (value.connectionGroup.source?.designPart?.id === selectedDesignPart.id) {
          map[key] = value;
        }
        return map;
      }, {} as ConnectionGroupMap);
    }
    return connectionGroupMap;
  }, [connectionGroupMap, selectedDesignPart]);

  // Initialize schematic
  useEffect(() => {
    const loadSchematic = async () => {
      if (schematicLoaded) {
        return;
      }

      // Wait for data to load before trying to set nodes and edges
      if (isSchematicDataLoaded && connectionGroupMap) {
        console.time('Load Schematic');

        const graph = buildGraph(selectedDesignPartConnectionGroupMap);
        console.debug('Graph:', graph);
        setNodes(graph.nodes);
        setEdges(graph.edges);
        await setViewport(defaultViewport);

        console.timeEnd('Load Schematic');

        setSchematicLoaded(true);
      }
    };

    void loadSchematic();
  }, [
    setNodes,
    setEdges,
    setViewport,
    isSchematicDataLoaded,
    schematicLoaded,
    connectionGroupMap,
    selectedDesignPartConnectionGroupMap,
  ]);

  return schematicLoaded;
};
