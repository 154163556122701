import { defaultLayoutConfig } from '@web/apps/Design';
import { RotatingShapeProps } from '../../../types/shapes.ts';

// Original dimensions of the svg
export const originalWidth = 39;
export const originalHeight = 43;

/**
 * Custom shape for the connector node (svg)
 * @param color
 * @param angle
 * @constructor
 */
export const ConnectorShape = ({ color = defaultLayoutConfig.nodeColor, angle = 0 }: RotatingShapeProps) => {
  // Calculate the center of rotation
  const centerX = originalWidth / 2;
  const centerY = originalHeight / 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M37 43C38.1046 43 39 42.1046 39 41L39 2C39 0.895431 38.1046 0 37 0H20.6346C20.197 0 19.7714 0.143531 19.4232 0.408586L5.78864 10.787C5.29172 11.1653 5 11.754 5 12.3785V13L1.90735e-06 13L0 30H5V30.6215C5 31.246 5.29172 31.8347 5.78864 32.2129L19.4232 42.5914C19.7714 42.8565 20.197 43 20.6346 43H37Z"
          fill={color}
        />
        <path d="M5 30H4L4 13H5L5 30Z" fill="#1A202C" />
      </g>
    </svg>
  );
};
