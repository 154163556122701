import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { Tool, ToolType } from '@senrasystems/senra-ui';
import useDebouncedValue from '@web/hooks/useDebouncedValue';
import { useTool, useTools, useDeleteTool } from '../api/queries';
import { DEFAULT_TOOLS_PAGE_SIZE } from '@web/api/tools-api';
import {
  Button,
  Center,
  Flex,
  HStack,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import Title from '@web/components/Title';
import Loading from '@web/components/Loading';
import FilterBox from '@web/components/FilterBox';
import Paginate from '@web/components/Paginate';
import { useState, useCallback } from 'react';
import ToolsLibraryModal from './ToolsLibraryModal.tsx';
import generateURlSearch from '@web/common/lib/api-utils/generateURLSearch.ts';
import { UseFormProps } from 'react-hook-form';

export interface CustomColumnDef {
  header: string;
  renderFn: (tool: Tool) => React.ReactNode;
}

interface Props {
  additionalFieldsComponent?: React.JSX.ElementType;
  buttonText: string;
  columnDefs?: CustomColumnDef[];
  defaultValues?: UseFormProps<Tool>['defaultValues'];
  routeName: string;
  title: string;
  toolType: ToolType;
}

export enum Mode {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
}

const ToolsLibraryDashboard = ({
  additionalFieldsComponent,
  buttonText,
  columnDefs,
  defaultValues,
  routeName,
  title,
  toolType,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mode, setMode] = useState(Mode.READ);
  const [isCreating, setIsCreating] = useState(false);

  const page = searchParams.get('page') ?? '1';
  const partNumber = searchParams.get('part_number') ?? '';
  const debouncedPartNumber = useDebouncedValue(partNumber, 500);

  const { isLoading, data: tools } = useTools(toolType, debouncedPartNumber, page, DEFAULT_TOOLS_PAGE_SIZE);
  const { toolId } = useParams<{ toolId: string }>();
  const { data: tool } = useTool(toolId);

  const handleAddTool = () => {
    setIsCreating(true);
    setMode(Mode.CREATE);
  };

  const handleFilterQuery = (value: string) => {
    if (value) {
      searchParams.set('part_number', value);
    } else {
      searchParams.delete('part_number');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const handlePageClick = (event: { selected: number }) => {
    searchParams.set('page', (event.selected + 1).toString());
    setSearchParams(searchParams);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    setIsCreating(false);
    navigate(`/tools/${routeName}${generateURlSearch({ page: page })}`);
  };

  const handleViewTool = useCallback(
    (tool: Tool) => {
      navigate(
        `/tools/${routeName}/${tool.id}${generateURlSearch({
          page: page,
          mode: Mode.READ,
          part_number: partNumber,
        })}`,
      );

      setMode(Mode.READ);
    },
    [navigate, page, partNumber, routeName],
  );

  const deleteTool = useDeleteTool(toolType);

  const onDelete = async () => {
    if (toolId) {
      await deleteTool.mutateAsync(toolId);
      navigate(`/tools/${routeName}${generateURlSearch({ page: page })}`);
    }
  };

  return (
    <Stack direction="column" w="full" h="full" spacing={8}>
      <Spacer />
      <Flex justifyContent={'space-between'} px={8}>
        <Title title={title} aria-label="page-title" />
        <Button onClick={handleAddTool}>+ Add {buttonText}</Button>
        <ToolsLibraryModal
          defaultValues={tool ? tool : defaultValues}
          title={title}
          toolType={toolType}
          mode={mode}
          isOpen={isCreating || !!tool}
          onClose={handleClose}
          additionalFieldsComponent={additionalFieldsComponent}
          enableEditMode={() => setMode(Mode.UPDATE)}
          onDelete={onDelete}
        />
      </Flex>
      <HStack gap={6} px={8}>
        <FilterBox placeholder="Type to filter tools" autoFocus value={partNumber} onChange={handleFilterQuery} />
        <Button
          onClick={() => {
            handleFilterQuery('');
          }}
        >
          Clear filters
        </Button>
      </HStack>
      <HStack alignItems="flex-start" spacing={0} height="full">
        <Flex width="full" height="100%" overflowY="auto">
          {isLoading ? (
            <Center height="50vh" width="80vw">
              <Loading message="Loading tools…" />
            </Center>
          ) : (
            <VStack width="full">
              <TableContainer width="full">
                <Table variant="senraTable">
                  <Thead>
                    <Tr>
                      <Th>Part Number</Th>
                      <Th>Manufacturer</Th>
                      {columnDefs?.map((columnDef) => <Th key={columnDef.header}>{columnDef.header}</Th>)}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tools?.data.map((tool) => (
                      <Tr key={tool.id} role="group" onClick={() => handleViewTool(tool)}>
                        <Td>
                          <Text paddingRight={4}>{tool.partNumber}</Text>
                        </Td>
                        <Td>{tool.manufacturer?.name}</Td>
                        {columnDefs?.map((columnDef) => <Td key={columnDef.header}>{columnDef.renderFn(tool)}</Td>)}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Paginate
                onPageChange={handlePageClick}
                selectedPage={parseInt(page) - 1}
                pageCount={tools?.meta.totalPages ?? 0}
              />
            </VStack>
          )}
        </Flex>
      </HStack>
    </Stack>
  );
};

export default ToolsLibraryDashboard;
