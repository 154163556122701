import { addMonths } from 'date-fns';
import TextInput from '../../../components/form/TextInput';
import TextAreaInput from '../../../components/form/TextAreaInput';
import { Control, UseFormRegister } from 'react-hook-form';
import { Order } from '@web/common/lib';
import S3FileInput from '../../../components/form/S3FileInput';
import { Badge, Box, Button, HStack, Stack, VStack } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

interface Props {
  control: Control;
  index: number; // index or id of line
  readOnly?: boolean;
  onDeleteLine?: (lineId: number) => void;
  register: UseFormRegister<{ order: Order }>;
}

const getDateString = (d: Date) => d.toISOString().split('T')[0];

const LineItemForm = ({ control, index, readOnly = false, onDeleteLine, register }: Props) => {
  return (
    <Stack borderWidth={1} p={4} spacing={4}>
      <HStack>
        <Badge flex="1">Line {index + 1}</Badge>
        {!readOnly && (
          <Button variant="ghost" color="red.200" leftIcon={<DeleteIcon />} onClick={() => onDeleteLine?.(index)}>
            Delete Line
          </Button>
        )}
      </HStack>
      <input type="hidden" {...register(`order.lineItems.${index}.id`)} />
      <HStack align="flex-start" spacing={4}>
        <VStack w="100%">
          <HStack w="100%">
            <TextInput
              label="Part number *"
              {...register(`order.lineItems.${index}.partNumber`, { required: true })}
              formControlProps={{ isReadOnly: readOnly }}
            />
            <TextInput
              maxLength={15}
              label="Part revision"
              {...register(`order.lineItems.${index}.partRevision`)}
              formControlProps={{ isReadOnly: readOnly }}
            />
            <TextInput
              label="Quantity *"
              type="number"
              min={1}
              max={1000}
              {...register(`order.lineItems.${index}.quantity`, { required: true })}
              formControlProps={{ isReadOnly: readOnly }}
            />
            <TextInput
              required
              type="date"
              label="Need by *"
              defaultValue={getDateString(addMonths(new Date(), 1))}
              {...register(`order.lineItems.${index}.neededBy`, { required: true })}
              formControlProps={{ isReadOnly: readOnly }}
            />
          </HStack>

          <TextAreaInput
            label="Description"
            placeholder="Additional description for the item"
            maxLength={1000}
            rows={1}
            {...register(`order.lineItems.${index}.description`)}
            formControlProps={{ isReadOnly: readOnly }}
          />
        </VStack>
        {!readOnly && (
          <Box w="400px">
            <S3FileInput
              control={control}
              name={`order.lineItems.${index}.documents`}
              label="Documents *"
              hint="Click or drag documents here"
            />
          </Box>
        )}
      </HStack>
    </Stack>
  );
};

export default LineItemForm;
