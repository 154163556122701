import { produce } from 'immer';
import { Edge } from '@xyflow/react';
import {
  defaultMeasurementEdgeData,
  MeasurementEdgeData,
} from '../components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { defaultSegmentEdgeData, SegmentEdgeData } from '../components/edges/SegmentEdge/SegmentEdge.tsx';
import { defaultSegmentNoteEdgeData, SegmentNoteEdgeData } from '../components/edges/NoteEdge/SegmentNoteEdge.tsx';
import { CustomEdgeData, CustomEdgeDataMap, EdgeType } from '../types.ts';
import { normalizeSourceTargetIds, UUID } from '@senrasystems/senra-ui';
import { HandleTypes } from '../types/handles.ts';

/**
 * Factory class for creating edges in the graph.
 */
class EdgeFactory {
  /**
   * Creates an edge with the given parameters.
   * @param source
   * @param target
   * @param edgeType - The type of the edge (e.g., 'Measurement' or 'Segment')
   * @param defaultData - The default data for the edge type
   * @param edgeData - Additional data for the edge
   * @param edgeProps - Additional properties for the edge
   */
  private static createEdge<T extends CustomEdgeData>(
    source: string,
    target: string,
    edgeType: EdgeType,
    defaultData?: T,
    edgeData: Partial<T> = {},
    edgeProps?: Partial<Edge>,
  ): Edge<T> {
    // Use Immer to create a deep copy of edgeData
    const clonedEdgeData = produce(edgeData, (draft) => draft) as T;

    return {
      ...edgeProps,
      id: window.crypto.randomUUID(),
      type: edgeType,
      source,
      target,
      data: { ...defaultData, ...clonedEdgeData },
    };
  }

  /**
   * Creates a measurement edge.
   * @param sourceId - The ID of the source node
   * @param targetId - The ID of the target node
   * @param edgeData - Additional data for the edge
   */
  static createMeasurementEdge(
    sourceId: string,
    targetId: string,
    edgeData?: Partial<MeasurementEdgeData>,
  ): Edge<MeasurementEdgeData> {
    // Since this is an undirected graph, normalize the source and target nodes
    const { source, target } = normalizeSourceTargetIds(sourceId, targetId);

    return EdgeFactory.createEdge<MeasurementEdgeData>(
      source,
      target,
      EdgeType.Measurement,
      defaultMeasurementEdgeData,
      edgeData,
      { sourceHandle: HandleTypes.Measurement, targetHandle: HandleTypes.Measurement },
    );
  }

  /**
   * Creates a segment edge.
   * @param sourceId - The ID of the source node
   * @param targetId - The ID of the target node
   * @param edgeData - Additional data for the edge
   */
  static createSegmentEdge(
    sourceId: string,
    targetId: string,
    edgeData?: Partial<SegmentEdgeData>,
  ): Edge<SegmentEdgeData> {
    // Since this is an undirected graph, normalize the source and target nodes
    const { source, target } = normalizeSourceTargetIds(sourceId, targetId);

    return EdgeFactory.createEdge<SegmentEdgeData>(source, target, EdgeType.Segment, defaultSegmentEdgeData, edgeData, {
      sourceHandle: HandleTypes.Segment,
      targetHandle: HandleTypes.Segment,
    });
  }

  /**
   * Creates a part note edge.
   * @param sourceId - The ID of the source node
   * @param targetId - The ID of the target node
   */
  static createPartNoteEdge(sourceId: string, targetId: string): Edge {
    return EdgeFactory.createEdge(sourceId, targetId, EdgeType.PartNote, undefined, undefined, {
      targetHandle: HandleTypes.Note,
    });
  }

  /**
   * Creates a segment note edge.
   * @param sourceId - The ID of the source node
   * @param targetId - The ID of the target node
   * @param edgeData - Additional data for the edge
   */
  static createSegmentNoteEdge(
    sourceId: string,
    targetId: string,
    edgeData?: Partial<SegmentNoteEdgeData>,
  ): Edge<SegmentNoteEdgeData> {
    return EdgeFactory.createEdge(sourceId, targetId, EdgeType.SegmentNote, defaultSegmentNoteEdgeData, edgeData);
  }
}

/**
 * Wrapper function to check if an edge exists in an undirected graph and add it to the edges array.
 * @param edgesArray - The current state of edges in the graph
 * @param newEdge - The edge to be added if it doesn't already exist
 * @returns The new edge if it was added, or undefined if it already exists
 */
const addEdgeIfNotExists = <T extends CustomEdgeData>(edgesArray: Edge[], newEdge: Edge): Edge<T> | undefined => {
  const edgeExists = edgesArray.some(
    (edge) =>
      edge.type === newEdge.type &&
      (edge.id === newEdge.id || (edge.source === newEdge.source && edge.target === newEdge.target)),
  );

  if (!edgeExists) {
    edgesArray.push(newEdge);
    return newEdge as Edge<T>;
  } else {
    // eslint-disable-next-line no-console
    console.debug(`Edge from ${newEdge.source} to ${newEdge.target} already exists.`);
    return undefined;
  }
};

/**
 * Removes an edge from the edges array if it exists.
 * @param edgesArray - The array of edges.
 * @param edgeId - The ID of the edge to remove.
 */
export const removeEdgeIfExists = (edgesArray: Edge[], edgeId: string): void => {
  const index = edgesArray.findIndex((edge) => edge.id === edgeId);
  if (index > -1) {
    edgesArray.splice(index, 1);
  }
};

/**
 * Wrapper function to create and add a measurement edge if it doesn't already exist.
 */
export const createAndAddMeasurementEdge = (
  edgesArray: Edge[],
  sourceId: string,
  targetId: string,
  edgeData?: Partial<MeasurementEdgeData>,
): Edge<MeasurementEdgeData> | undefined => {
  const newEdge = EdgeFactory.createMeasurementEdge(sourceId, targetId, edgeData);
  return addEdgeIfNotExists(edgesArray, newEdge);
};

/**
 * Wrapper function to create and add a segment edge if it doesn't already exist.
 */
export const createAndAddSegmentEdge = (
  edgesArray: Edge[],
  sourceId: string,
  targetId: string,
  edgeData?: Partial<SegmentEdgeData>,
): Edge<SegmentEdgeData> | undefined => {
  const newEdge = EdgeFactory.createSegmentEdge(sourceId, targetId, edgeData);
  return addEdgeIfNotExists(edgesArray, newEdge);
};

/**
 * Wrapper function to create and add a part note edge if it doesn't already exist.
 */
export const createAndAddPartNoteEdge = (edgesArray: Edge[], sourceId: string, targetId: string): Edge | undefined => {
  const newEdge = EdgeFactory.createPartNoteEdge(sourceId, targetId);
  return addEdgeIfNotExists(edgesArray, newEdge);
};

/**
 * Wrapper function to create a part note edge if it doesn't already exist.
 */
export const createPartNoteEdge = (sourceId: string, targetId: string): Edge | undefined =>
  EdgeFactory.createPartNoteEdge(sourceId, targetId);

/**
 * Wrapper function to create and add a segment note edge if it doesn't already exist.
 */
export const createAndAddSegmentNoteEdge = (
  edgesArray: Edge[],
  sourceId: string,
  targetId: string,
  edgeData?: Partial<SegmentNoteEdgeData>,
): Edge<SegmentNoteEdgeData> | undefined => {
  const newEdge = EdgeFactory.createSegmentNoteEdge(sourceId, targetId, edgeData);
  return addEdgeIfNotExists(edgesArray, newEdge);
};

/**
 * Wrapper function to create and add a segment note edge if it doesn't already exist.
 */
export const createSegmentNoteEdge = (
  sourceId: string,
  targetId: string,
  edgeData?: Partial<SegmentNoteEdgeData>,
): Edge<SegmentNoteEdgeData> | undefined => EdgeFactory.createSegmentNoteEdge(sourceId, targetId, edgeData);

/**
 * Finds an edge in the given edges array that connects the source and target nodes.
 * @param edges
 * @param sourceNodeId
 * @param targetNodeId
 * @param additionalCheck
 */
export const findEdge = (
  edges: Edge[],
  sourceNodeId: UUID,
  targetNodeId: UUID,
  additionalCheck?: (edge: Edge) => boolean,
): Edge | undefined => {
  const { source, target } = normalizeSourceTargetIds(sourceNodeId, targetNodeId);
  return edges.find((edge) => {
    return edge.source === source && edge.target === target && (!additionalCheck || additionalCheck(edge));
  });
};

/**
 * Finds an edge by ID in the given edges array. If the edge is not found, logs an error and returns undefined.
 * @param edges
 * @param edgeId - The ID of the edge to find.
 * @param additionalCheck - Optional. A function that returns a boolean indicating whether the edge is valid.
 * @returns The edge if found, or undefined if not found.
 */
export const findEdgeById = (
  edges: Edge[],
  edgeId: UUID,
  additionalCheck?: (edge: Edge) => boolean,
): Edge | undefined => {
  const edge = edges.find((e) => e.id === edgeId && (!additionalCheck || additionalCheck(e)));
  if (!edge) {
    // eslint-disable-next-line no-console
    console.warn(`Edge with id ${edgeId} not found.`);
  }
  return edge;
};

/**
 * Updates a specific property or the entire data object for a single edge.
 * @param edges - The array of edges to update.
 * @param edgeId - The ID of the edge to update.
 * @param newData - The new data to set for the edge. Can be a specific property or the entire data object.
 */
export const updateEdgeData = <
  T extends keyof CustomEdgeData,
  U extends Partial<CustomEdgeData> = Partial<CustomEdgeData>,
>(
  edges: Edge[],
  edgeId: string,
  newData: U | { property: T; value: U[T] },
): Edge[] => {
  // Use Immer to create a new draft and modify it in place
  return produce(edges, (draft) => {
    const edge = draft.find((e) => e.id === edgeId);

    if (edge?.data) {
      if ('property' in newData) {
        // Update a specific property
        (edge.data as U)[newData.property] = newData.value;
      } else {
        // Merge with existing data if it's a partial object
        edge.data = {
          ...edge.data,
          ...newData,
        };
      }
    }
  }) as Edge[];
};

/**
 * Updates all edges of a certain type, either by replacing the entire data object or by updating a specific property.
 * Supports updating with a partial data object.
 * @param edges - The array of edges to update.
 * @param edgeType - The type of edges to target for the update.
 * @param newData - The new data to set, either as an object or as a specific property and its value.
 * @param condition - Optional. A function that returns a boolean indicating whether the update should be applied to an edge.
 */
export const updateEdgesOfType = <
  T extends EdgeType,
  U extends Partial<CustomEdgeDataMap[T]> = Partial<CustomEdgeDataMap[T]>,
>(
  edges: Edge[],
  edgeType: T,
  newData: U | { property: keyof U; value: U[keyof U] },
  condition?: (edge: Edge<CustomEdgeDataMap[T]>) => boolean,
): void => {
  produce(edges, (draft) => {
    draft.forEach((edge) => {
      if (edge.type === edgeType && edge.data && (!condition || condition(edge as Edge<CustomEdgeDataMap[T]>))) {
        if ('property' in newData) {
          // Update a specific property
          (edge.data as U)[newData.property] = newData.value;
        } else {
          // Merge with existing data if it's a partial object
          edge.data = {
            ...edge.data,
            ...newData,
          };
        }
      }
    });
  });
};

/**
 * Validates the edge type. If the edge type is not supported, logs an error and returns false.
 * @param edgeType - The edge type to validate.
 * @param validEdgeTypes - An array of valid edge types.
 * @returns A boolean indicating whether the edge type is valid.
 */
export const isValidateEdgeType = (edgeType: string, validEdgeTypes: string[]): boolean => {
  if (!validEdgeTypes.includes(edgeType)) {
    // eslint-disable-next-line no-console
    console.warn(`Unsupported edge type: ${edgeType}.`);
    return false;
  }
  return true;
};
