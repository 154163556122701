import { ShapeProps } from '../../../types/shapes.ts';

export const ResisterShape = ({ color }: ShapeProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54916 4.75161C9.8605 4.77206 10.1267 4.98314 10.2175 5.28163L13.1101 14.7858L14.8077 10.7115C14.9241 10.4321 15.1972 10.25 15.5 10.25H18.5C18.9142 10.25 19.25 10.5858 19.25 11C19.25 11.4142 18.9142 11.75 18.5 11.75H16L13.6923 17.2885C13.5706 17.5805 13.2788 17.7648 12.9628 17.7491C12.6468 17.7334 12.3746 17.521 12.2825 17.2184L9.36083 7.61863L7.68277 11.3104C7.56107 11.5781 7.29411 11.75 7 11.75H4C3.58579 11.75 3.25 11.4142 3.25 11C3.25 10.5858 3.58579 10.25 4 10.25H6.51707L8.81722 5.18965C8.94634 4.9056 9.23782 4.73116 9.54916 4.75161Z"
        fill="white"
      />
    </svg>
  );
};
