import { useEffect, useState } from 'react';

export const useSaveOnBlurConnectionItem = <T>(
  isEditing: boolean,
  isDirty: boolean,
  saveConnection: (state: T) => Promise<void>,
  state: T,
) => {
  const [isSaving, setIsSaving] = useState(false);

  // If row is not in edit mode and it's dirty and it's not already saving, save the changes resetting the isDirty flag to false
  useEffect(() => {
    const saveOnBlur = async () => {
      if (!isEditing && isDirty && !isSaving) {
        setIsSaving(true);
        await saveConnection(state);
        setIsSaving(false);
      }
    };

    void saveOnBlur();
  }, [isEditing, isDirty, saveConnection, state, isSaving]);
};
