import React, { ReactNode, forwardRef } from 'react';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import HintText from './HintText';
import ErrorText from './ErrorText';

interface Props {
  label: string;
  options: ReactNode;
  error?: string | null;
  hint?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectInput = forwardRef((props: Props, ref: React.Ref<HTMLSelectElement>) => {
  const { options, label, error, hint, disabled, onChange, ...rest } = props;
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Select ref={ref} onChange={onChange} isDisabled={disabled} {...rest}>
        {options}
      </Select>
      {error && <ErrorText>{error}</ErrorText>}
      {hint && <HintText>{hint}</HintText>}
    </FormControl>
  );
});

export default SelectInput;
