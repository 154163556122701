import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, BoxProps, Icon, Text } from '@chakra-ui/react';
import { DesignPart, PartGroup, PartType } from '@senrasystems/senra-ui';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import PartListItem from './PartListItem.tsx';
import { useCallback, useEffect, useState } from 'react';
import { FilterBar } from './FilterBar.tsx';
import ConnectorList from './ConnectorList.tsx';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';
import { useDesignOverview } from '../../../../hooks/useDesignOverview.tsx';
import { useDesign } from '../../../../hooks/useDesign.tsx';
import { PartGroupTitle } from './PartGroupTitle.tsx';

interface Props extends BoxProps {
  expandAllItems?: boolean;
  onAddPart?: (types: PartType[], connector?: DesignPart) => void;
  onReplacePart?: (part: DesignPart) => void;
  hasFocus: boolean;
}

/**
 * DesignPartList component displays a list of parts grouped by type.
 * @param expandAllItems
 * @param onAddPart
 * @param onReplacePart
 * @param hasFocus
 * @param rest
 * @constructor
 */
const PartList = ({ expandAllItems = false, onAddPart, onReplacePart, hasFocus, ...rest }: Props) => {
  const { isViewOnly } = useDesign();
  const { designParts } = useDesignParts();
  const { lengthUnit } = useDesignOverview();
  const [filter, setFilter] = useState('');
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  // Filter parts by name or part number
  const filteredParts = designParts.filter((part) => {
    const partNumber = part.partData.partNumber.toLowerCase();
    const name = part.name.toLowerCase();
    const filterLower = filter.toLowerCase();
    return (partNumber.includes(filterLower) || name.includes(filterLower)) as boolean;
  });

  const filterByTypes = (types: PartType[]) => (part: DesignPart) => types.includes(part.partData.type);

  // Group all connectors
  const connectors = filteredParts.filter(filterByTypes([PartType.CONNECTOR]));

  // Group all conductors (cables + wires)
  const conductors = filteredParts.filter(filterByTypes([PartType.CABLE, PartType.WIRE]));

  // Group all splices
  const splices = filteredParts.filter(filterByTypes([PartType.SPLICE]));

  // Group all passives
  const passives = filteredParts.filter(filterByTypes([PartType.PASSIVE]));

  // Group all pigtails
  const pigtails = filteredParts.filter(filterByTypes([PartType.PIGTAIL]));

  // Group all generic parts
  const generics = filteredParts.filter(filterByTypes([PartType.GENERIC]));

  // Group all ungrouped parts
  const ungroupedParts = filteredParts.filter(
    (part) => part.partData.type === PartType.BACKSHELL || part.partData.type === PartType.CONTACT,
  );

  // Group parts into Connectors and Conductors
  const partGroups: PartGroup[] = [
    { name: 'Connectors', types: [PartType.CONNECTOR], parts: connectors },
    { name: 'Conductors', types: [PartType.CABLE, PartType.WIRE], parts: conductors },
    { name: 'Splices', types: [PartType.SPLICE], parts: splices },
    { name: 'Passives', types: [PartType.PASSIVE], parts: passives },
    { name: 'Pigtails', types: [PartType.PIGTAIL], parts: pigtails },
    { name: 'Generic', types: [PartType.GENERIC], parts: generics },
  ];

  // Handle toggling of accordion items
  const handleToggle = (index: number) => {
    setExpandedItems((prev) => (prev.includes(index) ? prev.filter((item) => item !== index) : [...prev, index]));
  };

  // Expand all accordion items
  const expandAll = useCallback(() => {
    // Generate array of indices from 0 to partGroups.length
    const indices = Array.from({ length: partGroups.length }, (_, i) => i);
    setExpandedItems(indices); // Empty the array to collapse all
  }, [partGroups.length]);

  // Expand all items when expandAllItems changes
  useEffect(() => {
    if (expandAllItems) {
      expandAll();
    }
  }, [expandAll, expandAllItems]);

  // Render the filter bar
  const renderFilterBar = () => {
    if (!designParts || designParts.length === 0) {
      return <Text p={4}>No parts found</Text>;
    }
    return <FilterBar filter={filter} setFilter={setFilter} h="50px" />;
  };

  const renderParts = (partGroup: PartGroup) => {
    // No parts found
    if (partGroup.parts.length === 0 && partGroup.name !== 'Connectors') {
      return (
        <Text p={4} ml={5}>
          No parts found
        </Text>
      );
    }

    // Render connectors
    if (partGroup.name === 'Connectors') {
      return (
        <ConnectorList
          connectors={partGroup.parts}
          ungroupedParts={ungroupedParts}
          onAddPart={onAddPart}
          onReplacePart={onReplacePart}
          designLengthUnit={lengthUnit}
          hasFocus={hasFocus}
        />
      );
    }

    // Default render
    return partGroup.parts.map((part) => (
      <PartListItem
        key={part.id}
        part={part}
        designLengthUnit={lengthUnit}
        onReplacePart={onReplacePart}
        hasFocus={hasFocus}
        pl={4}
      />
    ));
  };

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" {...rest}>
      <Box shadow="sm">{renderFilterBar()}</Box>
      {/* Scrollable content */}
      <Box overflowY="auto">
        <Accordion allowMultiple index={expandedItems}>
          {partGroups.map((partGroup, index) => (
            <AccordionItem key={partGroup.name} borderTop="1px solid" borderColor="gray.300" w="full" border="none">
              {({ isExpanded }) => (
                <Box>
                  <AccordionButton
                    as={Box}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    onClick={(event) => {
                      event.preventDefault();
                      handleToggle(index);
                    }}
                  >
                    <Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} mb={1} mr={1} />
                    <Box flex="1" textAlign="left">
                      <PartGroupTitle
                        partGroup={partGroup}
                        showAddButton={!isViewOnly && !!onAddPart}
                        onAddClick={(event) => {
                          event.stopPropagation();
                          setExpandedItems([...expandedItems, index]);
                          onAddPart?.(partGroup.types);
                        }}
                        hasFocus={hasFocus}
                      />
                    </Box>
                  </AccordionButton>
                  <AccordionPanel px={0} py={0}>
                    {renderParts(partGroup)}
                  </AccordionPanel>
                </Box>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Box>
  );
};

export default PartList;
