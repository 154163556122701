import { MouseEvent, useEffect, useState } from 'react';
import { defaultLayoutConfig } from '../config.ts';
import { useLayoutBuilder } from './useLayoutBuilder.tsx';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import { useLayoutActions } from './actions/useLayoutActions.tsx';
import { isBreakoutPointNode, isDesignPartNode, isMeasurementEdge, isSegmentEdge } from '../types.ts';
import { SELECTION_TYPES, useDesign } from '../../../hooks/useDesign.tsx';
import { useUpdateLayoutEffects } from './useUpdateLayoutEffects.tsx';

/**
 * Hook to manage ReactFlow events and context menus.
 */
export const useLayout = (layoutLoaded: boolean) => {
  // Settings for the layout
  const [config, setConfig] = useState(defaultLayoutConfig);

  // Design Context
  const { isViewOnly, selections, setSelection, clearSelections } = useDesign();

  // ReactFlow API methods
  const { getNodes, getEdges, getIntersectingNodes, setNodes } = useReactFlow();

  // Graph builder
  const { executeGraphOperation, saveLayout, updateLayout } = useLayoutBuilder();

  // Update layout notes
  useUpdateLayoutEffects(updateLayout, layoutLoaded);

  // Custom hook for all context menu operations
  const { closeAllMenus, onGraphElementContextMenu, onPaneContextMenu, contextMenus } = useLayoutActions();

  // Highlight the selected design part node
  useEffect(() => {
    if (selections.designPart) {
      setNodes((nds) =>
        nds.map((node) => ({
          ...node,
          selected: node.id === selections.designPart,
        })),
      );
    }
  }, [selections.designPart, setNodes]);

  // Pane click handler
  const onPaneClick = (event: MouseEvent) => {
    event.preventDefault();
    closeAllMenus();
    clearSelections();
    // eslint-disable-next-line no-console
    console.debug('Nodes:', getNodes());
    // eslint-disable-next-line no-console
    console.debug('Edges:', getEdges());
  };

  const onNodeClick = (_event: MouseEvent, node: Node) => {
    // eslint-disable-next-line no-console
    console.debug('Node clicked:', node);
    setSelection(SELECTION_TYPES.LAYOUT_ELEMENT, node.id);
    if (isDesignPartNode(node)) {
      setSelection(SELECTION_TYPES.DESIGN_PART, node.data.designPartId);
    }
  };

  const onEdgeClick = (_event: MouseEvent, edge: Edge) => {
    // eslint-disable-next-line no-console
    console.debug('Edge clicked:', edge);
    if (isSegmentEdge(edge) || isMeasurementEdge(edge)) {
      setSelection(SELECTION_TYPES.LAYOUT_ELEMENT, edge.id, true);
    }
  };

  // Node drag handler
  const onNodeDrag = (_event: MouseEvent, node: Node) => {
    closeAllMenus();
    if (isViewOnly || !isBreakoutPointNode(node)) {
      return;
    }
  };

  // Node drag stop handler
  const onNodeDragStop = (_event: MouseEvent, node: Node) => {
    // Don't do anything if the design is locked
    if (isViewOnly) {
      return;
    }

    // Check if the dragged node can be merged with another node
    if (isBreakoutPointNode(node)) {
      // Get the nodes that intersect with the dragged node
      const intersectingNodes = getIntersectingNodes(node, true);

      // Check if there is exactly one intersection, and it's of the same type as the dragged node
      const canMerge = intersectingNodes.length === 1 && isBreakoutPointNode(intersectingNodes[0]);

      if (canMerge) {
        // Merge the breakout points
        executeGraphOperation({
          type: 'MergeBreakoutPoints',
          params: { fromNodeId: node.id, toNodeId: intersectingNodes[0].id },
        });
        return;
      }
    }

    // Save the layout
    saveLayout(getNodes(), getEdges());
  };

  return {
    config,
    setConfig,
    onPaneContextMenu,
    onGraphElementContextMenu,
    closeAllMenus,
    onPaneClick,
    onNodeClick,
    onEdgeClick,
    onNodeDrag,
    onNodeDragStop,
    contextMenus,
  };
};
