import { ChangeEvent, useMemo, useState } from 'react';
import { Box, BoxProps, HStack, IconButtonProps, Select, SelectProps, Text } from '@chakra-ui/react';
import { useDesignId } from '../../../hooks/useDesignId.tsx';
import { useCreateDesignPartMutation } from '../../../api/queries.ts';
import { DesignPart, isConnectorDesignPart, Part, PartStatus } from '@senrasystems/senra-ui';
import AddIconButton from '../../../components/AddIconButton.tsx';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';

interface Props extends BoxProps {
  part: Part;
  connectorPart?: DesignPart;
  selectProps?: SelectProps;
  buttonProps?: IconButtonProps;
  visibleIfDraftPart?: boolean;
}

/**
 * AddAccessoryButton component is a button that allows the user to add an accessory. If a connectorPart is provided,
 * the accessory will be added to that connector. If no connectorPart is provided, the user can select a connector to
 * add the accessory to. The button is only visible if the part is released.
 * @param part
 * @param connectorPart
 * @param onPartAdded
 * @param selectProps
 * @param buttonProps
 * @param visibleIfDraftPart
 * @param rest
 * @constructor
 */
const AddAccessoryButton = ({
  part,
  connectorPart,
  selectProps,
  buttonProps,
  visibleIfDraftPart = false,
  ...rest
}: Props) => {
  const designId = useDesignId();
  const { showSuccessToast } = useDesignToast();

  const { designParts } = useDesignParts();
  const connectors = useMemo(() => designParts.filter(isConnectorDesignPart), [designParts]);
  const [selectedConnector, setSelectedConnector] = useState<DesignPart | null>(connectors[0]);

  const visibility = part.status === PartStatus.DRAFT && !visibleIfDraftPart ? 'hidden' : 'visible';

  // Mutation to add accessory to connector
  const handlePartCreateSuccess = () => {
    const connectorName = connectorPart ? connectorPart.name : selectedConnector?.name;
    showSuccessToast(
      'Accessory Added',
      `The ${part.type.toLowerCase()} was successfully added to connector ${connectorName}.`,
    );
  };
  const { mutate: addDesignPart, isPending } = useCreateDesignPartMutation(handlePartCreateSuccess);

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const connector = connectors.find((connector) => connector.id === event.target.value);
    if (connector) {
      setSelectedConnector(connector);
    }
  };

  const handleAddClick = () => {
    const designPartId = connectorPart?.id || selectedConnector?.id;
    if (designPartId) {
      addDesignPart({
        designId,
        data: { name: window.crypto.randomUUID(), partId: part.id, designPartId: designPartId },
      });
    }
  };

  if (connectors.length === 0) {
    return;
  }

  return (
    <Box visibility={visibility} {...rest}>
      <HStack alignItems="center" px={0} py={0}>
        {connectorPart ? (
          <HStack>
            <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {connectorPart.name}
            </Text>
            <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="blue.500">
              {connectorPart.partData.partNumber}
            </Text>
          </HStack>
        ) : (
          <Select onChange={handleSelect} {...selectProps}>
            {connectors.map((connector) => (
              <option
                key={connector.id}
                value={connector.id}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {`${connector.name} ${connector.partData.partNumber}`}
              </option>
            ))}
          </Select>
        )}
        <AddIconButton
          aria-label="Add"
          onClick={handleAddClick}
          bg="gray.300"
          _hover={{ color: 'white', bg: 'blue.500' }}
          isLoading={isPending || !selectedConnector}
          {...buttonProps}
        />
      </HStack>
    </Box>
  );
};

export default AddAccessoryButton;
