import { NoteGroupNodeType } from '../../components/nodes/NoteGroupNode/NoteGroupNode.tsx';
import { NodeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { useActionsBase } from './useActionsBase.tsx';
import { useFlagNoteActions } from './flagNotes/useFlagNoteActions.tsx';

/**
 * Hook for note group actions.
 */
export const useNoteGroupActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const node = element as NoteGroupNodeType;

  const { flagNoteActions } = useFlagNoteActions(closeMenu, { noteGroupNodeId: node?.id });

  const menu = renderMenu(flagNoteActions);

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.NoteGroup,
  } as MenuActions;
};
