import { Edge } from '@xyflow/react';
import { Operations } from '../Operations.ts';
import { isSegmentEdge, NodeType } from '../../types.ts';
import {
  createAndAddBreakoutPointNode,
  createAndAddLayoutPointNode,
  isValidateNodeType,
} from '../../graph/NodeFactory.ts';
import {
  createAndAddMeasurementEdge,
  createAndAddSegmentEdge,
  findEdgeById,
  removeEdgeIfExists,
} from '../../graph/EdgeFactory.ts';
import { findMeasurementEdge, getMidpoint } from '../../utils/graph.ts';
import { UUID } from '@senrasystems/senra-ui';
import { SegmentEdgeData } from '../../components/edges/SegmentEdge/SegmentEdge.tsx';
import { MeasurementEdgeData } from '../../components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { Graph } from '../../../../../types/reactFlow.ts';

// Operation to add a control point
export type AddControlPointOperation = {
  type: 'AddControlPoint';
  params: {
    nodeType: NodeType.BreakoutPoint | NodeType.LayoutPoint;
    edgeId: UUID;
  };
};

/**
 * Creates a new control point node and splits the edge into two segments.
 */
export class AddControlPoint implements Operations<AddControlPointOperation> {
  // Rebuild the graph after the operation
  requiresRebuild = true;

  // Execute the operation
  execute(graph: Graph, operation: AddControlPointOperation): Graph {
    const { nodeType, edgeId } = operation.params;

    // Validate parameters
    if (
      !isValidateNodeType(nodeType, [NodeType.BreakoutPoint, NodeType.LayoutPoint]) ||
      !findEdgeById(graph.edges, edgeId, isSegmentEdge)
    ) {
      // eslint-disable-next-line no-console
      console.warn('Invalid parameters for AddControlPoint operation.', nodeType, edgeId);
      return graph;
    }

    // Find the edge to split
    const edge = findEdgeById(graph.edges, edgeId);
    if (!edge || !isSegmentEdge(edge)) {
      // eslint-disable-next-line no-console
      console.warn(`Edge not found for id ${edgeId}.`);
      return graph;
    }

    if (nodeType === NodeType.BreakoutPoint) {
      // Find the measurement edge to split
      const measurementEdge = findMeasurementEdge(graph.edges, edge.source, edge.target);
      if (!measurementEdge) {
        // eslint-disable-next-line no-console
        console.warn(`Measurement edge not found for edge ${edge.id}.`);
        return graph;
      }
      this.addBreakoutPoint(graph, edge, measurementEdge);
    }

    if (nodeType === NodeType.LayoutPoint) {
      this.addLayoutPoint(graph, edge);
    }

    // Return the updated graph state
    return graph;
  }

  /**
   * Adds a breakout point to the graph, splitting the edge into two segments. Each segment will have a measurement edge
   * that is half the length of the original measurement edge.
   * @param graph
   * @param segmentEdge
   * @param measurementEdge
   */
  private addBreakoutPoint = (
    graph: Graph,
    segmentEdge: Edge<SegmentEdgeData>,
    measurementEdge: Edge<MeasurementEdgeData>,
  ): void => {
    const { x, y } = getMidpoint(graph.nodes, measurementEdge);
    const newNode = createAndAddBreakoutPointNode(graph.nodes, { x, y });

    // Divide the measurement by 2
    const halfMeasurement = (measurementEdge.data?.measurement || 0) / 2;

    // Create the first segment and measurement edge (source -> newNode)
    const m1 = createAndAddMeasurementEdge(graph.edges, measurementEdge.source, newNode.id, {
      ...measurementEdge.data,
      measurement: halfMeasurement,
    });
    createAndAddSegmentEdge(graph.edges, measurementEdge.source, newNode.id, {
      ...segmentEdge.data,
      measurementSource: m1?.source,
      measurementTarget: m1?.target,
    });

    // Create the second measurement edge (newNode -> target)
    const m2 = createAndAddMeasurementEdge(graph.edges, newNode.id, measurementEdge.target, {
      ...measurementEdge.data,
      measurement: halfMeasurement,
    });
    createAndAddSegmentEdge(graph.edges, newNode.id, measurementEdge.target, {
      ...segmentEdge.data,
      measurementSource: m2?.source,
      measurementTarget: m2?.target,
    });

    // Remove the original measurement edge
    removeEdgeIfExists(graph.edges, measurementEdge.id);
    removeEdgeIfExists(graph.edges, segmentEdge.id);
  };

  /**
   * Adds a layout point to the graph, splitting the edge into two segments. No measurement edges are created.
   * @param graph
   * @param segmentEdge
   */
  private addLayoutPoint = (graph: Graph, segmentEdge: Edge<SegmentEdgeData>): void => {
    const { x, y } = getMidpoint(graph.nodes, segmentEdge);
    const newNode = createAndAddLayoutPointNode(graph.nodes, { x, y });
    createAndAddSegmentEdge(graph.edges, segmentEdge.source, newNode.id, segmentEdge.data);
    createAndAddSegmentEdge(graph.edges, newNode.id, segmentEdge.target, segmentEdge.data);
    removeEdgeIfExists(graph.edges, segmentEdge.id);
  };
}
