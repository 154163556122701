import { Box } from '@chakra-ui/react';
import { centerAbsolute } from '@web/apps/styles.ts';
import { Handle, Position } from '@xyflow/react';

interface Props {
  id?: string;
}

export const CenterHandle = ({ id = '' }: Props) => (
  <Box sx={{ ...centerAbsolute, visibility: 'hidden' }}>
    <Handle id={id} type="source" position={Position.Top} />
  </Box>
);
