import { Edge } from '@xyflow/react';
import { ConductorEdge, ConductorEdgeData } from '../components/edges/ConductorEdge.tsx';

export enum EdgeType {
  Conductor = 'Conductor',
}

export type CustomEdgeData = ConductorEdgeData;

export type CustomEdgeDataMap = {
  [EdgeType.Conductor]: ConductorEdgeData;
};

export const edgeTypes = {
  Conductor: ConductorEdge,
};

export const isConductorEdge = (edge: Edge): edge is Edge<ConductorEdgeData> & { data: ConductorEdgeData } => {
  return edge.type === EdgeType.Conductor && edge.data !== undefined;
};
