import { Flex, useDisclosure } from '@chakra-ui/react';
import WiringList from '../WiringList/WiringList.tsx';
import { ContextPanelWithTabs } from '../../components/ContextPanel';
import Schematic from './Schematic.tsx';
import { ReactFlowProvider } from '@xyflow/react';

const tabs = [{ value: 'wiring', headerLabel: 'Wiring List', content: <WiringList /> }];

/**
 * Schematic component displays a schematic view of the selected design part's connections.
 * @constructor
 */
const SchematicWithDrawer = () => {
  const { isOpen, onToggle, onOpen } = useDisclosure();

  return (
    <Flex flexDirection="column" flex={1}>
      <ReactFlowProvider>
        <Schematic />
        <ContextPanelWithTabs isOpen={isOpen} togglePanel={onToggle} openPanel={onOpen} tabs={tabs} />
      </ReactFlowProvider>
    </Flex>
  );
};

export default SchematicWithDrawer;
