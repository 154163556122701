import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Table, Tbody, Th, Tr, Thead, useBoolean } from '@chakra-ui/react';
import { Accessory, Part, PartType } from '@senrasystems/senra-ui';
import CompatibleAccessoryRow from './CompatibleAccessoryRow.tsx';

interface Props {
  partType: PartType;
  editing: boolean;
  labelId?: string;
  maxIncluded?: number;
  customColumns?: { header: string; renderFn: (part: Part, index: number) => React.ReactNode }[];
}

const CompatibleAccessoryTable = ({ partType, editing, labelId, maxIncluded = Infinity, customColumns }: Props) => {
  const { control, watch } = useFormContext<Part>();
  const { fields: accessories, append, remove, update } = useFieldArray({ control, name: 'accessories' });
  const [isMaxIncludedReached, setIsMaxIncludedReached] = useBoolean();

  const watchedAccessories = watch('accessories', []);

  const isIncludedAndMatchesType = (accessory: Accessory) =>
    accessory.included && !accessory._destroy && accessory.type === partType;
  const includedAccessories = watchedAccessories.filter(isIncludedAndMatchesType);

  if (includedAccessories.length >= maxIncluded && !isMaxIncludedReached) {
    setIsMaxIncludedReached.on();
  } else if (includedAccessories.length < maxIncluded && isMaxIncludedReached) {
    setIsMaxIncludedReached.off();
  }

  const handleAddRow = () => {
    append({ type: partType, included: false, torqueValue: null });
  };

  return (
    <Table aria-labelledby={labelId} size="sm">
      <Thead w="full">
        <Tr>
          <Th w="250px">Part</Th>
          <Th w="200px" />
          {customColumns?.map((columnDef) => <Th key={columnDef.header}>{columnDef.header}</Th>)}
          {editing ? (
            <Th textAlign="right">
              <Button onClick={handleAddRow}>+</Button>
            </Th>
          ) : (
            <Th />
          )}
        </Tr>
      </Thead>
      <Tbody>
        {accessories.map((item, index) => (
          <CompatibleAccessoryRow
            key={item.id}
            index={index}
            partType={partType}
            editing={editing}
            maxIncluded={maxIncluded}
            isMaxIncludedReached={isMaxIncludedReached}
            remove={remove}
            update={update}
            customColumns={customColumns}
          />
        ))}
      </Tbody>
    </Table>
  );
};

export default CompatibleAccessoryTable;
