import { defaultLayoutConfig } from '../apps/Design';

/**
 * Common SVG definitions used in the layout. This should only be rendered once in the layout.
 * @constructor
 */
const SVGDefinitions = () => (
  <svg style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }}>
    <defs>
      {/* SVG Pattern for a segment with overwrap */}
      <pattern
        id="diagonal-stripe-pattern"
        patternUnits="userSpaceOnUse"
        width="3"
        height="8"
        patternTransform="rotate(45)"
      >
        <line x1="0" y1="0" x2="0" y2="10" style={{ stroke: defaultLayoutConfig.overWrapEdgeColor1, strokeWidth: 2 }} />
        <line x1="2" y1="0" x2="2" y2="10" style={{ stroke: defaultLayoutConfig.overWrapEdgeColor2, strokeWidth: 2 }} />
      </pattern>
      {/* SVG Pattern for a segment with overwrap, and selected */}
      <pattern
        id="diagonal-stripe-pattern-selected"
        patternUnits="userSpaceOnUse"
        width="3"
        height="8"
        patternTransform="rotate(45)"
      >
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="10"
          style={{ stroke: defaultLayoutConfig.overWrapEdgeSelectedColor1, strokeWidth: 2 }}
        />
        <line
          x1="2"
          y1="0"
          x2="2"
          y2="10"
          style={{ stroke: defaultLayoutConfig.overWrapEdgeSelectedColor2, strokeWidth: 2 }}
        />
      </pattern>
      {/* SVG Marker for a measurement arrow head */}
      <marker
        id="measurement-arrow"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="5"
        markerHeight="5"
        orient="auto-start-reverse"
        style={{ fill: defaultLayoutConfig.measurementEdgeColor }}
      >
        <path d="M 0 0 L 10 5 L 0 10 z" />
      </marker>
      <marker
        id="flag-note-arrow"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="5"
        markerHeight="5"
        orient="auto-start-reverse"
        style={{ fill: defaultLayoutConfig.edgeColor }}
      >
        <path d="M 0 0 L 10 5 L 0 10 z" />
      </marker>
    </defs>
  </svg>
);

export default SVGDefinitions;
