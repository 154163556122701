import { FormProvider, useForm } from 'react-hook-form';
import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';
import { Cable, Part, PartType } from '@senrasystems/senra-ui';
import { Text } from '@chakra-ui/react';
import { formatConductorDiameter } from '../../utils.tsx';

const CablesDashboard = () => {
  const methods = useForm<Cable>({ defaultValues: { type: PartType.CABLE } });
  const columns: CustomColumnDef[] = [
    {
      header: 'Shielded',
      renderFn: (part: Part) => {
        const cable = part as Cable;
        return <Text>{cable.shielded}</Text>;
      },
    },
    {
      header: 'Shield Type',
      renderFn: (part: Part) => {
        const cable = part as Cable;
        return <Text>{cable.shieldType}</Text>;
      },
    },
    {
      header: 'Jacket Color',
      renderFn: (part: Part) => {
        const cable = part as Cable;
        return <Text>{cable.jacket}</Text>;
      },
    },
    {
      header: 'Inner Diameter',
      renderFn: (part: Part) => {
        const cable = part as Cable;
        return <Text>{formatConductorDiameter(cable.innerDiameter, cable.diameterUnitOfMeasurement)}</Text>;
      },
    },
    {
      header: 'Outer Diameter',
      renderFn: (part: Part) => {
        const cable = part as Cable;
        return <Text>{formatConductorDiameter(cable.outerDiameter, cable.diameterUnitOfMeasurement)}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.CABLE} columnDefs={columns} />
    </FormProvider>
  );
};

export default CablesDashboard;
