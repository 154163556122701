import { Operations } from '../Operations.ts';
import { CustomEdgeData } from '../../types.ts';
import { findEdgeById, updateEdgeData } from '../../graph/EdgeFactory.ts';
import { UUID } from '@senrasystems/senra-ui';
import { Graph } from '../../../../../types/reactFlow.ts';

// Operation to update edge data
export type UpdateEdgeDataOperation = {
  type: 'UpdateEdgeData';
  params: {
    edgeId: UUID;
    data: Partial<CustomEdgeData>;
  };
};

/**
 * Updates the data of an edge.
 */
export class UpdateEdgeData implements Operations<UpdateEdgeDataOperation> {
  // Rebuild the graph after the operation
  requiresRebuild = true;

  // Execute the operation
  execute(graph: Graph, operation: UpdateEdgeDataOperation): Graph {
    const { nodes, edges } = graph;
    const { edgeId, data } = operation.params;

    // Validate parameters
    const edge = findEdgeById(edges, edgeId);
    if (!edge) {
      // eslint-disable-next-line no-console
      console.warn(`Edge not found for id ${edgeId}.`);
      return graph;
    }

    return {
      nodes,
      edges: updateEdgeData(edges, edgeId, data),
    };
  }
}
