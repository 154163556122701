import { RouteNames } from '@web/consts/routeNames.ts';

// Used by <Root /> to generate page titles for routes.
export const pageTitles = new Map<string, string[] | null[]>([
  [RouteNames.ROOT, [null]],
  [RouteNames.NOT_FOUND, ['Not Found']],
  [RouteNames.SIGN_OUT, ['Sign Out']],
  [RouteNames.PROFILE, ['Profile']],
  [RouteNames.COMPANY_PROFILE, ['Company Profile']],
  [RouteNames.PARTS.BACKSHELLS, ['Backshells', 'Part Library']],
  [RouteNames.PARTS.CABLES, ['Cables', 'Part Library']],
  [RouteNames.PARTS.CONNECTORS, ['Connectors', 'Part Library']],
  [RouteNames.PARTS.CONTACTS, ['Contacts', 'Part Library']],
  [RouteNames.PARTS.WIRES, ['Wires', 'Part Library']],
  [RouteNames.DESIGNS.INDEX, ['Designs', 'Design Tool']],
  [RouteNames.DESIGNS.DESIGN, ['Design', 'Design Tool']],
  [RouteNames.DESIGNS.DESIGN_PARTS, ['Assembly Navigator', 'Design Tool']],
  [RouteNames.DESIGNS.BOM, ['BOM', 'Design Tool']],
  [RouteNames.ORDERS.INDEX, ['Orders']],
  [RouteNames.ORDERS.NEW, ['New Order']],
  [RouteNames.ORDERS.SHOW, ['Order Details']],
  [RouteNames.ORDERS.UPLOAD_PURCHASE_ORDER, ['Upload Purchase Order']],
  [RouteNames.TENANTS.INDEX, ['Admin Tenants']],
  [RouteNames.TENANTS.NEW, ['New Tenant']],
  [RouteNames.USERS.INDEX, ['Admin Users']],
  [RouteNames.USERS.NEW, ['New User']],
  [RouteNames.MANUAL_QUOTE_UPLOAD, ['Upload a Quote', 'Admin']],
  [RouteNames.DECLINE_ORDER, ['Decline an Order', 'Admin']],
]);
