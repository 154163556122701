import { Box, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { compact } from 'lodash';
import Title from '@web/components/Title.tsx';
import { ReactNode } from 'react';
import { useDesignOverview } from '../../../hooks/useDesignOverview.tsx';
import { PartRevisionLabel } from '../../../components/PartRevisionLabel.tsx';

interface Props {
  windowIcon?: ReactNode;
}

export const AssemblyNavigatorPartDetails = ({ windowIcon }: Props) => {
  const { partNumber, partRevision, description, tenant } = useDesignOverview();
  const subtitleFields = compact([tenant.name, description]).join(' / ');

  return (
    <Flex flexDirection="column" p={4}>
      <Title
        title={
          <HStack>
            <PartRevisionLabel partNumber={partNumber} partRevision={partRevision} badgeProps={{ p: 1 }} />
            <Box>{windowIcon}</Box>
          </HStack>
        }
        as="h2"
        size="md"
      />
      <Tooltip label={subtitleFields}>
        <Text noOfLines={1}>{subtitleFields}</Text>
      </Tooltip>
    </Flex>
  );
};
