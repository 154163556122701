import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

interface Props {
  onClick: () => void;
  isOpen: boolean;
}

export const CollapseButton = ({ onClick, isOpen }: Props) => (
  <Box
    position="absolute"
    top={5}
    right={0}
    transform="translateX(50%)"
    borderWidth={1}
    borderRadius="50%"
    backgroundColor="blue.500"
    color="white"
    _hover={{ backgroundColor: 'blue.400' }}
    cursor="pointer"
    onClick={onClick}
  >
    {isOpen ? (
      <ChevronLeftIcon boxSize={8} pointerEvents="none" />
    ) : (
      <ChevronRightIcon boxSize={8} pointerEvents="none" />
    )}
  </Box>
);
