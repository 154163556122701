import { Box, Button, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { RouteNames } from '@web/consts/routeNames';

interface NavigableToolType {
  name: string;
  path: string;
}

interface NavigableToolTypes {
  [key: string]: NavigableToolType & { subType?: NavigableToolType };
}

const ToolsSideNav = () => {
  const navigableToolTypes: NavigableToolTypes = {
    TORQUE_ADAPTER: { name: 'Torque Adapter', path: RouteNames.TOOLS.TORQUE_ADAPTERS },
    CRIMP: { name: 'Crimp', path: RouteNames.TOOLS.CRIMPS },
    TOOL_BODY: { name: 'Tool Body', path: RouteNames.TOOLS.TOOL_BODIES },
    POSITIONER: { name: 'Positioner', path: RouteNames.TOOLS.POSITIONERS },
    DIE_SET: { name: 'Die Set', path: RouteNames.TOOLS.DIE_SETS },
    TURRET_HEAD: { name: 'Turret Head', path: RouteNames.TOOLS.TURRET_HEADS },
    INSERTION_REMOVAL: { name: 'Insertion + Removal', path: RouteNames.TOOLS.INSERTIONS_REMOVALS },
  };

  return (
    <Box minW="225px" h="full" borderRight="2px" borderColor="gray.200">
      <Box borderBottom="2px" borderColor="gray.200" p={8}>
        <Heading as="h1" size="md">
          Tool Types
        </Heading>
      </Box>

      <Stack spacing={0}>
        {Object.values(navigableToolTypes).map((type) => (
          <Box key={type.name}>
            <ToolTypeButton type={type} />
            {type.subType && <ToolTypeButton type={type.subType} />}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

const ToolTypeButton = ({ type }: { type: NavigableToolType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = matchPath(location.pathname, type.path);
  const activeBlue = useColorModeValue('blue.100', 'blue.800');
  const borderColor = useColorModeValue('white', 'gray.800');

  return (
    <Button
      justifyContent="start"
      variant="ghost"
      width="full"
      height="50px"
      borderRadius="0"
      borderWidth="2px"
      borderColor={isActive ? activeBlue : borderColor}
      boxSizing="border-box"
      onClick={() => navigate(type.path)}
      backgroundColor={isActive ? activeBlue : 'initial'}
      _hover={{ borderColor: 'blue.400' }}
    >
      {type.name}
    </Button>
  );
};

export default ToolsSideNav;
