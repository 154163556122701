import { Part, PartStatus, translatePartType } from '@senrasystems/senra-ui';
import {
  AccordionButton,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDeletePartMutation, useUpdatePartMutation } from '../../../api/queries.ts';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { PartFields, StatusBadge } from '../../../../../apps/Parts-Library';
import PartSummary from './PartSummary.tsx';

interface Props extends AccordionItemProps {
  part: Part;
  afterDeleteCallback: () => void;
  afterUpdateCallback: (partId: string) => void;
  children?: ReactNode;
}

/**
 * PartSearchListItem component displays a single part in a list of parts.
 * @param part
 * @param afterDeleteCallback
 * @param afterUpdateCallback
 * @param children
 * @param rest
 * @constructor
 */
const PartSearchListItem = ({ part, afterDeleteCallback, afterUpdateCallback, children, ...rest }: Props) => {
  const [isEditing, setIsEditing] = useBoolean();
  const formMethods = useForm<Part>({ defaultValues: part });
  const { showSuccessToast } = useDesignToast();

  const { mutateAsync: updatePartAsync } = useUpdatePartMutation({ onError: () => formMethods.resetField('status') });
  const { mutateAsync: deletePartAsync } = useDeletePartMutation();

  const isDraft = part.status === PartStatus.DRAFT;

  const handleDeletePartClick = async (partId: string) => {
    if (confirm('Are you sure you want to delete this part?')) {
      await deletePartAsync({ partId });
      afterDeleteCallback();
      showSuccessToast('Success', 'Part deleted.');
    }
  };

  const handleUpdatePart = async (newPart: Part) => {
    const updatedPart = await updatePartAsync({ partId: part.id, data: newPart });
    if (updatedPart) {
      afterUpdateCallback(updatedPart.id);
      showSuccessToast('Success', 'Part updated.');
      setIsEditing.off();
      formMethods.reset(updatedPart);
    }
  };

  const handleAccordionClick = (isExpanded: boolean) => {
    if (!isExpanded) {
      setIsEditing.off();
      formMethods.reset();
    }
  };

  return (
    <AccordionItem borderTop="1px solid" borderColor="gray.300" _hover={{ bg: 'gray.50' }} {...rest}>
      {({ isExpanded }) => (
        <Box>
          <HStack p={4} spacing={4} w="full" alignItems="center">
            <AccordionButton
              as={Flex}
              justifyContent="space-between"
              p={4}
              _hover={{ bg: 'inherit' }}
              onClick={() => handleAccordionClick(isExpanded)}
              role="button"
              aria-label="Expand Part Details"
            >
              <HStack spacing={2}>
                <Box textAlign="left" color="gray.500" w="65px">
                  <Text aria-label="Part Type" role="note" fontWeight="semibold">
                    {translatePartType(part.type)}
                  </Text>
                </Box>
                <Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} mx={2} />
                <Box color="gray.900">
                  <Text aria-label="Part Number" role="note" fontSize="lg">
                    {part.partNumber} {isDraft && <StatusBadge status={part.status} ml={1} />}
                  </Text>
                  <Text color="gray.500">
                    <PartSummary part={part} />
                  </Text>
                </Box>
              </HStack>
            </AccordionButton>
            <Box
              visibility="hidden"
              _groupHover={{ visibility: 'visible' }}
              onClick={(e) => e.stopPropagation()}
              alignItems="center"
              w="175px"
              display="flex"
              justifyContent="flex-end"
            >
              <Box
                visibility="hidden"
                _groupHover={{ visibility: 'visible' }}
                onClick={(e) => e.stopPropagation()}
                alignItems="center"
                w="175px"
                display="flex"
                justifyContent="flex-end"
              >
                {children}
              </Box>
            </Box>
          </HStack>
          <AccordionPanel>
            {isExpanded && (
              <Box p={4} borderRadius="md">
                <FormProvider {...formMethods}>
                  <form
                    onSubmit={async (event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      await formMethods.handleSubmit(handleUpdatePart)(event);
                    }}
                  >
                    <PartFields
                      partType={part.type}
                      isEditing={isEditing}
                      isUpdateMode={isEditing}
                      isCreateMode={false}
                      theme="light"
                      toggleEdit={setIsEditing.toggle}
                      onDeletePart={handleDeletePartClick}
                    />
                  </form>
                </FormProvider>
              </Box>
            )}
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
};

export default PartSearchListItem;
