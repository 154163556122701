import { Node } from '@xyflow/react';
import { ConnectorNode, ConnectorNodeData } from '../components/nodes/ConnectorNode.tsx';
import { CavityNode, CavityNodeData } from '../components/nodes/CavityNode.tsx';
import { SpliceNode, SpliceNodeData } from '../components/nodes/SpliceNode.tsx';

export enum NodeType {
  Connector = 'Connector',
  Cavity = 'Cavity',
  Splice = 'Splice',
}

export type CustomNodeData = ConnectorNodeData | CavityNodeData | SpliceNodeData;

export type CustomNodeDataMap = {
  [NodeType.Connector]: ConnectorNodeData;
  [NodeType.Cavity]: CavityNodeData;
  [NodeType.Splice]: SpliceNodeData;
};

export const nodeTypes = {
  Connector: ConnectorNode,
  Cavity: CavityNode,
  Splice: SpliceNode,
};

export const isConnectorNode = (node: Node): node is Node<ConnectorNodeData> => {
  return node.type === NodeType.Connector;
};

export const isCavityNode = (node: Node): node is Node<CavityNodeData> => {
  return node.type === NodeType.Cavity;
};

export const isSpliceNode = (node: Node): node is Node<SpliceNodeData> => {
  return node.type === NodeType.Splice;
};
