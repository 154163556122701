import { BaseEdge, Edge, EdgeProps, getBezierPath } from '@xyflow/react';
import { UUID } from '@senrasystems/senra-ui';
import { useToken } from '@chakra-ui/react';

export type ConductorEdgeData = {
  conductorId: UUID | null;
};

export const defaultConductorEdgeData: ConductorEdgeData = {
  conductorId: null,
};

export type ConductorEdge = Edge<ConductorEdgeData>;

/**
 * ConductorEdge component representing a conductor between two nodes.
 * @constructor
 * @param props
 */
export const ConductorEdge = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}: EdgeProps<ConductorEdge>) => {
  const color = useToken('colors', 'gray.500');

  const edgeStyle = {
    stroke: color,
    strokeWidth: 2,
  };

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return <BaseEdge path={edgePath} style={edgeStyle} />;
};
