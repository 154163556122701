import { Edge, Node } from '@xyflow/react';
import { Dispatch, SetStateAction } from 'react';

export interface Graph {
  nodes: Node[];
  edges: Edge[];
}

export const emptyGraph: Graph = {
  nodes: [],
  edges: [],
};

export type GraphElement = Node | Edge | null;

export const emptyNodes: Node[] = [];

export const emptyEdges: Edge[] = [];

export type setNodesType = Dispatch<SetStateAction<Node[]>>;

export type setEdgesType = Dispatch<SetStateAction<Edge[]>>;

export const defaultViewport = { x: 0, y: 0, zoom: 1 };

export const defaultPosition = { x: 0, y: 0 };
