import { useFormContext } from 'react-hook-form';
import { Box, FormControl, FormLabel, HStack, Select } from '@chakra-ui/react';
import { ConductorFields } from '@senrasystems/senra-ui';
import EditableFormText from '@web/components/form/EditableFormText';
import TextInput from '@web/components/form/TextInput';

interface Props {
  config: { diameterUnits: string[] };
  editing: boolean;
}

const ConductorDiameterFields = ({ config, editing }: Props) => {
  const { register, getValues } = useFormContext<ConductorFields>();

  return (
    <HStack>
      <Box flex={1}>
        <EditableFormText label="Inner Diameter" value={getValues('innerDiameter')} editing={editing}>
          <TextInput label="Inner Diameter" {...register('innerDiameter')} />
        </EditableFormText>
      </Box>
      <Box flex={1}>
        <EditableFormText label="Outer Diameter" value={getValues('outerDiameter')} editing={editing}>
          <TextInput label="Outer Diameter" {...register('outerDiameter')} />
        </EditableFormText>
      </Box>
      <Box flex={1}>
        <EditableFormText editing={editing} label="Unit of Measurement" value={getValues('diameterUnitOfMeasurement')}>
          <FormControl>
            <FormLabel>Unit of Measurement</FormLabel>
            <Select isDisabled={!editing} {...register('diameterUnitOfMeasurement')}>
              {config.diameterUnits.map((val) => {
                return (
                  <option key={val} value={val}>
                    {val}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </EditableFormText>
      </Box>
    </HStack>
  );
};

export default ConductorDiameterFields;
