import { Heading, HStack, Spacer } from '@chakra-ui/react';
import AddIconButton from '../../../components/AddIconButton';
import { useDesign } from '../../../hooks/useDesign';

interface Props {
  onAddPart: () => void;
}

export const AssemblyNavigatorTitle = ({ onAddPart }: Props) => {
  const { isViewOnly } = useDesign();

  return (
    <HStack p={3} fontWeight={500}>
      <Heading as="h2" size="md">
        Assembly Navigator
      </Heading>
      <Spacer />
      {!isViewOnly && <AddIconButton aria-label="Add Part" variant="primary" color="blue.500" onClick={onAddPart} />}
    </HStack>
  );
};
