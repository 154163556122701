import { forwardRef } from 'react';
import { Flex, Center, Box } from '@chakra-ui/react';
import { BsTriangle } from 'react-icons/bs';

interface Props {
  position: number;
}

export const TrianglePosition = forwardRef(({ position, ...props }: Props, ref: React.Ref<HTMLDivElement>) => (
  <Flex ref={ref} position="relative" justify="center" align="flex-end" {...props}>
    <Box as={BsTriangle} size={36} color="gray.300" />
    <Center position="absolute" top={0} right={0} bottom={0} left={0} color="gray.500">
      <Box mt={3}>{position}</Box>
    </Center>
  </Flex>
));
