import { Handle, Node, NodeProps, Position, useReactFlow, useUpdateNodeInternals } from '@xyflow/react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { UUID } from '@senrasystems/senra-ui';
import { HandleTypes } from '../../types/handles.ts';
import { NodeType } from '../../types/nodes.ts';
import { CAVITY_HEIGHT, CAVITY_WIDTH } from './CavityNode.tsx';
import { useEffect, useMemo } from 'react';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';

const PADDING = 10;

export enum ConnectorPosition {
  Left = 'Left',
  Right = 'Right',
}

export type ConnectorNodeData = {
  designPartId: UUID | null;
  position: ConnectorPosition;
};

export const defaultConnectorNodeData: ConnectorNodeData = {
  designPartId: null,
  position: ConnectorPosition.Left,
};

export type ConnectorNode = Node<ConnectorNodeData>;

/**
 * Connector node component.
 * @param props
 * @constructor
 */
export const ConnectorNode = (props: NodeProps<ConnectorNode>) => {
  const { designPartId } = props.data;
  const { getNodes, updateNode } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const { getDesignPartById } = useDesignParts();

  const cavities = useMemo(() => {
    return getNodes().filter((node: Node) => node.type === NodeType.Cavity && node.parentId === props.id);
  }, [getNodes, props.id]);

  useEffect(() => {
    cavities.forEach((cavity, index) => {
      const newPosition = {
        x: PADDING,
        y: PADDING + index * (CAVITY_HEIGHT + PADDING),
      };

      // Update only if position has changed
      if (cavity.position.x !== newPosition.x || cavity.position.y !== newPosition.y) {
        updateNode(cavity.id, {
          position: newPosition,
        });
      }
    });
  }, [cavities, updateNode]);

  useEffect(() => {
    updateNodeInternals(props.id);
  }, [updateNodeInternals, props.id]);

  const designPart = getDesignPartById(designPartId);
  const height = cavities.length * (CAVITY_HEIGHT + PADDING) + PADDING;
  const width = CAVITY_WIDTH + 2 * PADDING;
  const isLeft = props.data.position === ConnectorPosition.Left;

  return (
    <Box position="relative">
      <VStack
        position="absolute"
        top={-20}
        left={isLeft ? 0 : 'auto'}
        right={isLeft ? 'auto' : 0}
        alignItems={isLeft ? 'flex-start' : 'flex-end'}
        color="gray.950"
        fontFamily="mono"
      >
        <Text fontWeight="700">{designPart?.name}</Text>
        <Text>{designPart?.partData.partNumber}</Text>
      </VStack>
      <Box
        h={`${height}px`}
        w={`${width}px`}
        border="1px solid"
        borderColor="gray.600"
        borderRadius="md"
        bg="gray.600"
        opacity="0.5"
      />
      <Handle id={HandleTypes.Housing} type="source" position={Position.Bottom} />
    </Box>
  );
};
