import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';
import { Edge } from '@xyflow/react';
import { EdgeType, NodeType } from '../../types.ts';
import { GraphOperation } from '../../graph/Operations.ts';
import { useActionsBase } from './useActionsBase.tsx';
import { SegmentEdgeData } from '../../components/edges/SegmentEdge/SegmentEdge.tsx';
import { MenuActions } from './actionsTypes.ts';
import { useSegmentFlagNoteActions } from './flagNotes/useSegmentFlagNoteActions.tsx';
import { useOverwrapActions } from './useOverwrapActions.tsx';

/**
 * Hook for segment actions.
 */
export const useSegmentActions = () => {
  const { showMenu, closeMenu, renderMenu, executeOperation, element } = useActionsBase();
  const edge = element as Edge<SegmentEdgeData> & { data: SegmentEdgeData };

  // Overwrap actions
  const { handleAddOverwrap, overwrapActions } = useOverwrapActions(edge?.id);

  // Flag note actions
  const { flagNoteActions } = useSegmentFlagNoteActions(closeMenu, edge);

  const onAddOverwrap = () => {
    handleAddOverwrap();
    closeMenu();
  };

  const onAddBreakoutPoint = () => {
    const params: GraphOperation = {
      type: 'AddControlPoint',
      params: { nodeType: NodeType.BreakoutPoint, edgeId: edge.id },
    };
    executeOperation(params);
    closeMenu();
  };

  const onAddLayoutPoint = () => {
    const params: GraphOperation = {
      type: 'AddControlPoint',
      params: { nodeType: NodeType.LayoutPoint, edgeId: edge.id },
    };
    executeOperation(params);
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={onAddOverwrap}>Add overwrap</MenuItem>
        <MenuItem onClick={onAddBreakoutPoint}>Add breakout point</MenuItem>
        <MenuItem onClick={onAddLayoutPoint}>Add layout point</MenuItem>
      </MenuGroup>
      <MenuDivider />
      {flagNoteActions}
      <MenuDivider />
      {overwrapActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: EdgeType.Segment,
  } as MenuActions;
};
