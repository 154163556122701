import { ReactNode, useMemo } from 'react';
import { Accordion, AccordionProps } from '@chakra-ui/react';
import { Part, PartType } from '@senrasystems/senra-ui';
import CreatePartButton from '../../AssemblyNavigator/components/CreatePartButton.tsx';
import PartSearchListItem from './PartSearchListItem.tsx';

const CREATE_PART_ACCORDION_ITEM = { id: 'CREATE_PART_ACCORDION_ITEM_ID' };

interface Props extends AccordionProps {
  parts: Part[];
  setSearchValue: (value: string) => void;
  expandedPartId: string | null;
  setExpandedPartId: (id: string | null) => void;
  filterType: PartType;
  renderSearchResultAction?: (part: Part) => ReactNode;
}

/**
 * PartSearchList component displays a list of parts in an accordion.
 * @param parts
 * @param setSearchValue
 * @param expandedIndex
 * @param setExpandedIndex
 * @param expandedPartId
 * @param setExpandedPartId
 * @param showCreateButton
 * @param filterType
 * @param rest
 * @constructor
 */
const PartSearchList = ({
  parts,
  setSearchValue,
  expandedPartId,
  setExpandedPartId,
  filterType,
  renderSearchResultAction,
  ...rest
}: Props) => {
  const partsWithButtonId = useMemo(() => [...parts, CREATE_PART_ACCORDION_ITEM], [parts]);

  const expandedPartIndex = useMemo(
    () => partsWithButtonId.findIndex((part) => part.id === expandedPartId),
    [partsWithButtonId, expandedPartId],
  );

  const setIndex = (updatedExpandedIndex: number) => {
    const expandedPart = partsWithButtonId[updatedExpandedIndex];
    setExpandedPartId(expandedPart?.id || null);
  };

  const resetPartId = () => setExpandedPartId(null);

  const afterPartCreated = (part: Part) => {
    // Set search value to new part number to
    // have it show up in the search results
    setSearchValue(part.partNumber);
    setExpandedPartId(part.id);
  };

  return (
    <Accordion index={expandedPartIndex} onChange={setIndex} allowToggle {...rest}>
      {parts.map((part) => (
        <PartSearchListItem
          key={part.id}
          role="group"
          part={part}
          afterDeleteCallback={resetPartId}
          afterUpdateCallback={setExpandedPartId}
        >
          {renderSearchResultAction?.(part)}
        </PartSearchListItem>
      ))}
      <CreatePartButton display="inherit" afterCreateCallback={afterPartCreated} filterType={filterType} />
    </Accordion>
  );
};

export default PartSearchList;
