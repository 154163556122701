import { useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, VStack } from '@chakra-ui/react';
import { InsertionRemovalTool, InsertionRemovalToolSubtype } from '@senrasystems/senra-ui';
import { useConfiguration } from '@web/queries/admin.ts';

interface Props {
  editing: boolean;
}

const InsertionRemovalFields = ({ editing }: Props) => {
  const { setValue, watch } = useFormContext<InsertionRemovalTool>();
  const { data: config, isLoading } = useConfiguration();

  return (
    !isLoading && (
      <RadioGroup
        isDisabled={!editing}
        value={watch('subtype')}
        onChange={(value) => setValue('subtype', value as InsertionRemovalToolSubtype)}
      >
        <VStack alignItems="flex-start">
          {config?.tools.insertionRemovalTool.types.map((value, index) => {
            return (
              <Radio key={index} value={value}>
                {value}
              </Radio>
            );
          })}
        </VStack>
      </RadioGroup>
    )
  );
};

export default InsertionRemovalFields;
