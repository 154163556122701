import { ShapeProps } from './shapes.ts';
import { PassiveType } from '@senrasystems/senra-ui';
import { CapacitorShape } from '../components/nodes/PassiveNode/CapacitorShape.tsx';
import { DiodeShape } from '../components/nodes/PassiveNode/DiodeShape.tsx';
import { ResisterShape } from '../components/nodes/PassiveNode/ResisterShape.tsx';

// Passive types that are used in the layout
type PassiveTypesInLayout = PassiveType.CAPACITOR | PassiveType.DIODE | PassiveType.RESISTOR;

// Type guard for passive types in layout
export const isPassiveTypeInLayout = (type: string): type is PassiveTypesInLayout =>
  type === PassiveType.CAPACITOR || type === PassiveType.DIODE || type === PassiveType.RESISTOR;

// Map of passive types to their respective shape components
type PassiveShapeMap = {
  [key in PassiveTypesInLayout]: React.FC<ShapeProps>;
};

// Map of passive types to their respective shape components
export const passiveShapes: PassiveShapeMap = {
  [PassiveType.CAPACITOR]: CapacitorShape,
  [PassiveType.DIODE]: DiodeShape,
  [PassiveType.RESISTOR]: ResisterShape,
};
