import { Button, FormControl, FormLabel, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import ToolSettingRow from './ToolSettingRow';
import { Tool } from '@senrasystems/senra-ui';
import { FieldArrayWithId } from 'react-hook-form';

interface Props {
  editing: boolean;
  settings: FieldArrayWithId<Tool, 'settings', 'id'>[];
  onAddClick: () => void;
  remove: (index: number) => void;
  hasBarrelSize?: boolean;
  hasWireGauge?: boolean;
  hasColorCode?: boolean;
  hasSelectorSetting?: boolean;
}

const ToolSettingTable = ({
  editing,
  onAddClick,
  remove,
  settings,
  hasBarrelSize,
  hasWireGauge,
  hasColorCode,
  hasSelectorSetting,
}: Props) => (
  <FormControl>
    <FormLabel id="ToolSettingRows">Settings</FormLabel>
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            {hasBarrelSize && <Th>BARREL SIZE</Th>}
            {hasWireGauge && <Th>WIRE GAUGE</Th>}
            {hasColorCode && <Th>COLOR CODE</Th>}
            {hasSelectorSetting && <Th>SELECTOR SETTING</Th>}
            {editing && (
              <Th textAlign="right">
                <Button onClick={onAddClick}>+</Button>
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {settings.map((row, index) => (
            <ToolSettingRow
              key={row.id}
              index={index}
              hasBarrelSize={hasBarrelSize}
              hasWireGauge={hasWireGauge}
              hasColorCode={hasColorCode}
              hasSelectorSetting={hasSelectorSetting}
              editing={editing}
              remove={remove}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  </FormControl>
);

export default ToolSettingTable;
