import { useMemo } from 'react';
import { acceptsContacts, PartType, uniqueDesignPartsByPartNumber } from '@senrasystems/senra-ui';
import { Option } from '../../../components/SearchableDropdown.tsx';
import { useSelectedDesignPart } from '../../../hooks/useSelectedDesignPart.tsx';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';

/**
 * Valid source contact options are (unique) contacts on the selected design part
 */
export const useSourceContactOptions = () => {
  const selectedDesignPart = useSelectedDesignPart();
  const { designParts } = useDesignParts();

  const options = useMemo(() => {
    const contacts = acceptsContacts(selectedDesignPart) ? (selectedDesignPart?.contacts ?? []) : [];
    const ungroupedContacts = designParts.filter((part) => part.partData.type === PartType.CONTACT);
    return uniqueDesignPartsByPartNumber([...contacts, ...ungroupedContacts]).map(
      (contact): Option => ({
        label: contact.partData.partNumber,
        value: contact.id,
      }),
    );
  }, [designParts, selectedDesignPart]);

  return { options };
};
