import { Input, Text } from '@chakra-ui/react';

interface Props {
  isEditing: boolean;
  value: string;
  onChange: (value: string | boolean) => void;
}

export const ConnectionItemInput = ({ isEditing, value, onChange }: Props) => {
  return isEditing ? <Input value={value} onChange={(e) => onChange(e.target.value)} /> : <Text>{value}</Text>;
};
