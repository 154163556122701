import { S3Download } from './lib';
import { doesUserHaveRole, Role, User } from '@senrasystems/senra-ui';

export const fetchDocument = async (document: S3Download) => {
  const data = await fetch(document.url);
  return await data.blob();
};

export const getDocumentObjectURL = async (document: S3Download) => {
  const blob = await fetchDocument(document);
  return URL.createObjectURL(blob);
};

export const isTechnician = (user?: User): boolean =>
  user?.roles?.length === 1 && doesUserHaveRole(user, Role.TECHNICIAN);

export const valueToLabel = (value: string, options: { label: string; value: string }[]) => {
  return options.find((option) => option.value === value)?.label;
};
