import { ResolvedConnection, ResolvedConnectionPoint } from '@senrasystems/senra-ui';
import { useConnections } from '../../../hooks/useConnections.tsx';
import { useMemo } from 'react';
import { naturalCompare } from '@web/common/comparison.ts';
import { compact } from 'lodash';

export interface ConnectionGroup {
  source: ResolvedConnectionPoint | null;
  destination: ResolvedConnectionPoint | null;
  connections: ResolvedConnection[];
}

export interface ConnectionGroupMap {
  [designPartId: string]: {
    connectionGroup: ConnectionGroup;
  };
}

/**
 * useSchematic hook returns a map of connection points grouped by destination ID and connection point name.
 */
export const useSchematicData = () => {
  const { bidirectionalConnections: connections, isLoading, isSuccess, error } = useConnections();

  const sortedConnections = useMemo(() => {
    return connections.slice().sort((a, b) => {
      // Retrieve source connector names and connection point names
      const aSourceConnectorName = a.source?.designPart.name || '';
      const bSourceConnectorName = b.source?.designPart.name || '';
      const aConnectionPointName = a.source?.name || '';
      const bConnectionPointName = b.source?.name || '';

      // Retrieve conductor design part names
      const aConductorName = a.conductor?.designPart.name || '';
      const bConductorName = b.conductor?.designPart.name || '';

      // Create sort keys for grouping by source connector and connection point names
      const aGroupKey = `${aSourceConnectorName}:${aConnectionPointName}`;
      const bGroupKey = `${bSourceConnectorName}:${bConnectionPointName}`;

      // First, sort by group keys to ensure connections are grouped by source connection points
      const groupComparison = aGroupKey.localeCompare(bGroupKey);
      if (groupComparison !== 0) {
        return groupComparison;
      }

      // If group keys are the same, then sort by conductor name using natural comparison
      return naturalCompare(aConductorName, bConductorName);
    });
  }, [connections]);

  /**
   * A map of connection points grouped by the same source:destination design part ID.
   */
  const connectionGroupMap = useMemo(() => {
    // Create a map of connection points by destination ID.
    const map: ConnectionGroupMap = {};

    // Group connections by destination design part ID.
    sortedConnections.forEach((row) => {
      const { source, destination } = row;
      const key = compact([source?.designPart.id, destination?.designPart.id]).join(':');

      if (key?.length) {
        if (!map[key]) {
          map[key] = {
            connectionGroup: {
              source,
              destination,
              connections: [],
            },
          };
        }

        map[key].connectionGroup.connections.push(row);
      }
    });

    return map;
  }, [sortedConnections]);

  return {
    isLoading,
    isSuccess,
    error,
    connectionGroupMap: connectionGroupMap || {},
  };
};
