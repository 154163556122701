import { useActionsBase } from './useActionsBase.tsx';
import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';
import { GraphOperation } from '../../graph/Operations.ts';
import { LayoutPointNode } from '../../components/nodes/LayoutPointNode/LayoutPointNode.tsx';
import { MenuActions } from './actionsTypes.ts';
import { NodeType } from '../../types.ts';
import { usePartFlagNoteActions } from './flagNotes/usePartFlagNoteActions.tsx';

/**
 * Hook for layout point actions.
 */
export const useLayoutPointActions = () => {
  const { showMenu, closeMenu, renderMenu, executeOperation, element } = useActionsBase();
  const node = element as LayoutPointNode;

  const { flagNoteActions } = usePartFlagNoteActions(closeMenu, node);

  const onRemove = () => {
    const params: GraphOperation = { type: 'RemoveControlPoint', params: { nodeId: node.id } };
    executeOperation(params);
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={onRemove}>Remove</MenuItem>
      </MenuGroup>
      <MenuDivider />
      {flagNoteActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.LayoutPoint,
  } as MenuActions;
};
