import { ShapeProps } from '../../../types/shapes.ts';

export const BreakoutPointShape = ({ color }: ShapeProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <circle cx="7" cy="7" r="6.5" fill="white" stroke={color} />
      <circle cx="7" cy="7" r="4.5" fill="white" stroke={color} />
    </svg>
  );
};
