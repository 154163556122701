import { Node, NodeProps } from '@xyflow/react';
import { Box } from '@chakra-ui/react';
import { LayoutPointShape } from './LayoutPointShape.tsx';
import { CenterHandle } from '../common/CenterHandle.tsx';
import { getNodeColor } from '../../../utils/common.ts';
import { HandleTypes } from '../../../types/handles.ts';

export type LayoutPointNodeData = {
  value: string;
};

export const defaultLayoutPointNodeData: LayoutPointNodeData = {
  value: '',
};

export type LayoutPointNode = Node<LayoutPointNodeData>;

/**
 * Layout point node component.
 * @param props
 * @constructor
 */
export const LayoutPointNode = (props: NodeProps<LayoutPointNode>) => {
  const { selected } = props;

  return (
    <Box position="relative">
      <LayoutPointShape color={getNodeColor(selected)} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Measurement} />
      <CenterHandle id={HandleTypes.Note} />
    </Box>
  );
};
