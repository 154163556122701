import { DesignPart, Part, PartStatus } from '@senrasystems/senra-ui';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useReplaceDesignPartMutation } from '../../../api/queries.ts';
import { useDesignId } from '../../../hooks/useDesignId.tsx';
import { MouseEventHandler } from 'react';

interface Props extends ButtonProps {
  part: Part;
  partToReplace: DesignPart;
  onPartReplaced?: () => void;
  visibleIfDraftPart?: boolean;
}

/**
 * ReplacePartButton component displays a button to replace a part. When the button is clicked, the part is replaced
 * with the new part. The button is only visible if the part is released.
 * @param part
 * @param partToReplace
 * @param onClick
 * @param visibleIfDraftPart
 * @param rest
 * @constructor
 */
const ReplacePartButton = ({ part, partToReplace, onPartReplaced, visibleIfDraftPart = false, ...rest }: Props) => {
  const designId = useDesignId();
  const { mutate: replaceDesignPart, isPending } = useReplaceDesignPartMutation(onPartReplaced);
  const visibility = part.status === PartStatus.DRAFT ? (visibleIfDraftPart ? 'visible' : 'hidden') : 'visible';

  // Handle replacing a part
  const handleReplacePart: MouseEventHandler<HTMLButtonElement> = () => {
    replaceDesignPart({ designId, partId: partToReplace.id, newPartId: part.id });
  };

  return part.partNumber === partToReplace.partData.partNumber ? null : (
    <Button colorScheme="blue" onClick={handleReplacePart} isLoading={isPending} visibility={visibility} {...rest}>
      Replace
    </Button>
  );
};

export default ReplacePartButton;
