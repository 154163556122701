import { useCallback } from 'react';
import { Option } from '../../../components/SearchableDropdown';
import { ConnectionPoint, UUID } from '@senrasystems/senra-ui';
import { Fields } from '../wiringListTypes';

const excludedConnectionPointTypes = new Set(['Housing', 'Shield']);

export const useHandleConductorIdChange = (
  setStateWithDirtyFlag: (setStateAction: React.SetStateAction<Fields>) => void,
  sourceConnectionPoint: ConnectionPoint,
  connectionPointsById: { [key: UUID]: ConnectionPoint },
  sourceContactOptions: Option[],
  destinationContactOptions: Option[],
) => {
  const handleConductorIdChange = useCallback(
    (updatedConductorId: UUID) =>
      setStateWithDirtyFlag((prevState) => {
        const { destinationId, sourceContactId, destinationContactId } = prevState;
        const updatedState = { ...prevState, conductorId: updatedConductorId };

        if (updatedConductorId) {
          const shouldAddSourceContact = !excludedConnectionPointTypes.has(sourceConnectionPoint.name);

          const destinationConnectionPoint = connectionPointsById[destinationId];
          const shouldAddDestinationContact = !excludedConnectionPointTypes.has(destinationConnectionPoint?.name);

          if (shouldAddSourceContact && !sourceContactId && sourceContactOptions.length === 1) {
            updatedState.sourceContactId = sourceContactOptions[0].value;
          }

          if (shouldAddDestinationContact && !destinationContactId && destinationContactOptions.length == 1) {
            updatedState.destinationContactId = destinationContactOptions[0].value;
          }
        }

        return updatedState;
      }),
    [
      setStateWithDirtyFlag,
      sourceConnectionPoint.name,
      connectionPointsById,
      sourceContactOptions,
      destinationContactOptions,
    ],
  );

  return handleConductorIdChange;
};
