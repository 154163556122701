import { useFormContext } from 'react-hook-form';
import { Td, Tr, IconButton } from '@chakra-ui/react';
import { Positioner } from '@senrasystems/senra-ui';
import TextInput from '@web/components/form/TextInput.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import { MdCancel } from 'react-icons/md';

interface Props {
  editing: boolean;
  index: number;
  hasBarrelSize?: boolean;
  hasWireGauge?: boolean;
  hasColorCode?: boolean;
  hasSelectorSetting?: boolean;
  remove: (index: number) => void;
}

const ToolSpecificationRow = ({
  editing,
  index,
  hasBarrelSize,
  hasWireGauge,
  hasColorCode,
  hasSelectorSetting,
  remove,
}: Props) => {
  const { register, getValues } = useFormContext<Positioner>();

  return (
    <Tr>
      {hasBarrelSize && (
        <Td>
          <EditableFormText editing={editing} value={getValues(`settings.${index}.barrelSize`)}>
            <TextInput
              formControlProps={{ isDisabled: !editing }}
              type="text"
              {...register(`settings.${index}.barrelSize`)}
            />
          </EditableFormText>
        </Td>
      )}
      {hasWireGauge && (
        <Td>
          <EditableFormText editing={editing} value={getValues(`settings.${index}.wireGauge`)}>
            <TextInput
              formControlProps={{ isDisabled: !editing }}
              type="number"
              {...register(`settings.${index}.wireGauge`, {
                valueAsNumber: true,
              })}
            />
          </EditableFormText>
        </Td>
      )}
      {hasColorCode && (
        <Td>
          <EditableFormText editing={editing} value={getValues(`settings.${index}.colorCode`)}>
            <TextInput
              formControlProps={{ isDisabled: !editing }}
              type="text"
              {...register(`settings.${index}.colorCode`)}
            />
          </EditableFormText>
        </Td>
      )}
      {hasSelectorSetting && (
        <Td>
          <EditableFormText editing={editing} value={getValues(`settings.${index}.selectorSetting`)}>
            <TextInput
              formControlProps={{ isDisabled: !editing }}
              type="text"
              {...register(`settings.${index}.selectorSetting`)}
            />
          </EditableFormText>
        </Td>
      )}
      {editing && (
        <Td>
          <IconButton
            isRound
            variant="ghost"
            icon={<MdCancel size="1em" />}
            onClick={() => remove(index)}
            aria-label={`Remove setting row`}
          />
        </Td>
      )}
    </Tr>
  );
};

export default ToolSpecificationRow;
