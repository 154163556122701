import { useFormContext, useFieldArray } from 'react-hook-form';
import { Positioner } from '@senrasystems/senra-ui';
import ToolSettingTable from '../../components/ToolSettingTable.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

export const emptySetting = { barrelSize: '', wireGauge: '', selectorSetting: '' };

interface Props {
  editing: boolean;
}

const PositionerFields = ({ editing }: Props) => {
  const { control, register, getValues } = useFormContext<Positioner>();
  const {
    fields: settings,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'settings',
  });

  return (
    <>
      <EditableFormText label="Name" value={getValues('name')} editing={editing}>
        <TextInput label="Name" formControlProps={{ isDisabled: !editing }} {...register('name')} />
      </EditableFormText>
      <ToolSettingTable
        editing={editing}
        settings={settings}
        onAddClick={() => append(emptySetting)}
        remove={remove}
        hasBarrelSize
        hasWireGauge
        hasSelectorSetting
      />
    </>
  );
};

export default PositionerFields;
