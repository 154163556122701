import { useCurrentUser } from '../queries/users';

export enum featureFlag {
  Schematic = 'schematics',
  PopOuts = 'popOuts',
}

export const useFeatureFlag = (featureFlagName: featureFlag): boolean => {
  const { data: user, isLoading, isError } = useCurrentUser();

  if (isLoading || isError || !user) {
    return false;
  }

  return !!user.featureFlags?.[featureFlagName];
};
