import { PatternProps } from '../../../types/PatternProps';

export const ShieldBraid = ({ id, color = 'black', lightColor = 'white' }: PatternProps) => (
  <pattern id={`${id}-pattern`} patternUnits="userSpaceOnUse" width="8" height="8">
    <rect width="8" height="8" fill={lightColor} />
    <line x1="-2" y1="0" x2="2" y2="8" stroke={color} strokeWidth={0.75} />
    <line x1="0.67" y1="0" x2="4.67" y2="8" stroke={color} strokeWidth={0.75} />
    <line x1="3.33" y1="0" x2="7.33" y2="8" stroke={color} strokeWidth={0.75} />
    <line x1="6" y1="0" x2="10" y2="8" stroke={color} strokeWidth={0.75} />
    <line x1="1.5" y1="0" x2="-4" y2="8" stroke={color} strokeWidth={0.75} />
    <line x1="3.5" y1="0" x2="-2" y2="8" stroke={color} strokeWidth={0.75} />
    <line x1="5.5" y1="0" x2="0" y2="8" stroke={color} strokeWidth={0.75} />
    <line x1="9.5" y1="0" x2="4" y2="8" stroke={color} strokeWidth={0.75} />
  </pattern>
);
