import { DesignPart, isPassiveDesignPart } from '@senrasystems/senra-ui';
import { isPassiveTypeInLayout, passiveShapes } from '../../../types/passives.ts';

interface Props {
  designPart: DesignPart;
  color: string;
}

export const PassiveShape = ({ designPart, color }: Props) => {
  if (!isPassiveDesignPart(designPart) || !isPassiveTypeInLayout(designPart.partData.subtype)) {
    return null;
  }

  const ShapeComponent = passiveShapes[designPart.partData.subtype];

  return <ShapeComponent color={color} />;
};
