import { HStack, Text, VStack } from '@chakra-ui/react';
import { BsBox } from 'react-icons/bs';
import { isOverwrapDesignPart } from '@senrasystems/senra-ui';
import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { BaseAccordionItem } from './BaseAccordionItem.tsx';

interface OverwrapsSectionProps {
  overwraps: string[];
}

export const OverwrapsSection = ({ overwraps }: OverwrapsSectionProps) => {
  const { getOverwrapByPartNumber } = useDesignParts();

  if (overwraps.length === 0) {
    return null;
  }

  return (
    <BaseAccordionItem title="Overwraps" icon={BsBox} borderColor="purple.200" bgColor="purple.50">
      <VStack align="stretch">
        {overwraps.map((partNumber, index) => {
          const overwrap = getOverwrapByPartNumber(partNumber);
          if (overwrap && isOverwrapDesignPart(overwrap)) {
            return (
              <HStack key={partNumber} p={2} bg="white">
                <Text fontWeight="medium">{partNumber}</Text>
                <Text fontSize="sm" color="gray.600">
                  {overwrap.partData.subtype}
                </Text>
                <Text fontSize="sm" color="gray.600" ml="auto">
                  {index === 0 ? 'Innermost' : index === overwraps.length - 1 ? 'Outermost' : ''}
                </Text>
              </HStack>
            );
          }
          return null;
        })}
      </VStack>
    </BaseAccordionItem>
  );
};
