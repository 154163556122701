import { defaultLayoutConfig } from '@web/apps/Design';
import { RotatingShapeProps } from '../../../types/shapes.ts';

// Original dimensions of the svg
export const originalWidth = 39;
export const originalHeight = 43;

/**
 * Custom shape for the connector node (svg)
 * @param color
 * @param angle
 * @constructor
 */
export const PigtailShape = ({ color = defaultLayoutConfig.nodeColor, angle = 0 }: RotatingShapeProps) => {
  // Calculate the center of rotation
  const centerX = originalWidth / 2;
  const centerY = originalHeight / 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.43478 0C1.09009 0 0 1.16677 0 2.60606V40.3939C0 41.8332 1.09009 43 2.43478 43H25.5652C26.9099 43 28 41.8332 28 40.3939V36.0603C30.4602 35.3613 32.726 34.0436 34.5667 32.203C37.4053 29.3644 39 25.5144 39 21.5C39 17.4856 37.4053 13.6356 34.5667 10.797C32.726 8.95635 30.4602 7.63873 28 6.93975V2.60606C28 1.16677 26.9099 0 25.5652 0H2.43478ZM28 9.78053V33.2195C29.7337 32.6075 31.3268 31.6127 32.6516 30.2879C34.9823 27.9572 36.2916 24.7961 36.2916 21.5C36.2916 18.2039 34.9823 15.0428 32.6516 12.7121C31.3268 11.3873 29.7337 10.3925 28 9.78053Z"
          fill={color}
        />
        <path
          d="M28.9092 28.7879C30.396 28.7879 31.8219 28.0971 32.8733 26.8674C33.9246 25.6377 34.5152 23.9699 34.5152 22.2309C34.5152 15.7336 34.5152 13.4117 34.5152 22.2309C34.5152 24.7378 30.83 27.4765 28.9092 27.4765L28.9092 28.7879Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
