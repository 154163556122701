import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Box, Text } from '@chakra-ui/react';
import { UUID } from '@senrasystems/senra-ui';
import { useConnections } from '../../../../hooks/useConnections.tsx';
import { HandleTypes } from '../../types/handles.ts';

export const CAVITY_HEIGHT = 25;
export const CAVITY_WIDTH = 35;

export type CavityNodeData = {
  connectionPointId: UUID | null;
};

export const defaultCavityNodeData: CavityNodeData = {
  connectionPointId: null,
};

export type CavityNode = Node<CavityNodeData>;

/**
 * Cavity node component.
 * @param props
 * @constructor
 */
export const CavityNode = (props: NodeProps<CavityNode>) => {
  const { connectionPointsById } = useConnections();
  const { connectionPointId } = props.data;
  const connectionPoint = connectionPointId ? connectionPointsById[connectionPointId] : null;

  return (
    <Box
      w={`${CAVITY_WIDTH}px`}
      h={`${CAVITY_HEIGHT}px`}
      border="1px solid"
      borderColor="gray.600"
      borderRadius="md"
      bg="white"
      textAlign="center"
      lineHeight={`${CAVITY_HEIGHT}px`}
    >
      <Text>{connectionPoint?.name}</Text>
      <Handle type="source" id={HandleTypes.Source} position={Position.Right} />
      <Handle type="target" id={HandleTypes.Target} position={Position.Left} />
    </Box>
  );
};
