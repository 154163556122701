import { ReactNode } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { FieldValues, UseFormProps } from 'react-hook-form';
import ModalContentForm from './ModalContentForm.tsx';

interface Props<T extends FieldValues> extends ModalProps {
  defaultValues: UseFormProps<T>['defaultValues'];
  onSubmit: (data: T) => Promise<T | undefined>;
  header: ReactNode | string;
  footer?: ReactNode;
  children: ReactNode;
  successToastMsg: string;
  minWidth?: string[] | string;
  closeButton?: boolean;
}

const SkinnyModalForm = <T extends FieldValues>({
  defaultValues,
  isOpen,
  onSubmit,
  successToastMsg,
  size,
  onClose,
  header,
  footer,
  children,
  minWidth,
  closeButton,
}: Props<T>) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent minWidth={minWidth}>
        <ModalContentForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          onClose={onClose}
          successToastMsg={successToastMsg}
        >
          <ModalHeader>{header}</ModalHeader>
          {closeButton && <ModalCloseButton />}
          <ModalBody>{children}</ModalBody>
          <ModalFooter>{footer}</ModalFooter>
        </ModalContentForm>
      </ModalContent>
    </Modal>
  );
};

export default SkinnyModalForm;
