import { Button, HStack } from '@chakra-ui/react';
import { Mode } from './ToolsLibraryDashboard.tsx';

interface Props {
  mode: Mode;
  enableEditMode: () => void;
  onDelete?: () => void;
}

const ToolFieldsActionBar = ({ mode, enableEditMode, onDelete }: Props) => {
  return (
    <HStack justifyContent="flex-end">
      {(mode === Mode.CREATE || mode === Mode.UPDATE) && (
        <Button type="submit" colorScheme="green">
          Save
        </Button>
      )}
      {mode === Mode.READ && (
        <HStack>
          <Button colorScheme="blue" onClick={enableEditMode}>
            Edit
          </Button>
          {onDelete && (
            <Button
              colorScheme="red"
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this tool?')) {
                  onDelete();
                }
              }}
            >
              Delete
            </Button>
          )}
        </HStack>
      )}
    </HStack>
  );
};

export default ToolFieldsActionBar;
