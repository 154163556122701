import { Node, NodeProps } from '@xyflow/react';
import { Box, Text } from '@chakra-ui/react';
import { UUID } from '@senrasystems/senra-ui';
import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { SpliceShape } from './SpliceShape.tsx';
import { CenterHandle } from '../common/CenterHandle.tsx';
import { getNodeColor } from '../../../utils/common.ts';
import { HandleTypes } from '../../../types/handles.ts';

export type SpliceNodeData = {
  designPartId: UUID;
};

export const defaultSpliceNodeData: SpliceNodeData = {
  designPartId: '',
};

export type SpliceNode = Node<SpliceNodeData>;

/**
 * Splice node component.
 * @param props
 * @constructor
 */
export const SpliceNode = (props: NodeProps<SpliceNode>) => {
  const { data = defaultSpliceNodeData, selected } = props;
  const designPart = useDesignParts().getDesignPartById(data.designPartId);

  return (
    <Box position="relative">
      <Text position="absolute" top="-20px">
        {designPart?.name}
      </Text>
      <SpliceShape color={getNodeColor(selected)} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Measurement} />
      <CenterHandle id={HandleTypes.Note} />
    </Box>
  );
};
