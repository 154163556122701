import { getStraightPath, XYPosition } from '@xyflow/react';
import { calculateDistance } from '../../../utils/geometry.ts';

interface Props {
  sourcePosition: XYPosition;
  targetPosition: XYPosition;
  distanceFromEdge: number;
  flipped: boolean;
}

/**
 * Calculates the paths for the measurement line, start line, and end line. Also returns the midpoint.
 * @param sourcePosition
 * @param targetPosition
 * @param distanceFromEdge
 * @param flipped
 */
export const useMeasurementPaths = ({ sourcePosition, targetPosition, distanceFromEdge, flipped }: Props) => {
  // Get the source and target positions
  const { x: sourceX, y: sourceY } = sourcePosition;
  const { x: targetX, y: targetY } = targetPosition;

  // Calculate the distance between the source and target nodes
  const dx = targetX - sourceX;
  const dy = targetY - sourceY;
  const length = calculateDistance({ x: sourceX, y: sourceY }, { x: targetX, y: targetY });

  // Flip factor for mirroring
  const flipFactor = flipped ? -1 : 1;

  // Calculate the normalized vector for the measurement line
  const normX = (dy / length) * (distanceFromEdge - 5) * flipFactor;
  const normY = (-dx / length) * (distanceFromEdge - 5) * flipFactor;

  // Calculate adjustment to shorten the measurement line
  const shortenOffset = 3; // Adjust by 5 pixels inward
  const adjustedSourceX = sourceX + (dx / length) * shortenOffset;
  const adjustedSourceY = sourceY + (dy / length) * shortenOffset;
  const adjustedTargetX = targetX - (dx / length) * shortenOffset;
  const adjustedTargetY = targetY - (dy / length) * shortenOffset;

  // Get the straight path for the measurement line with adjusted positions
  const [measurementLinePath] = getStraightPath({
    sourceX: adjustedSourceX + normX,
    sourceY: adjustedSourceY + normY,
    targetX: adjustedTargetX + normX,
    targetY: adjustedTargetY + normY,
  });

  // Get the straight path for the start line
  const [startLinePath] = getStraightPath({
    sourceX: sourceX,
    sourceY: sourceY,
    targetX: sourceX + (dy / length) * distanceFromEdge * flipFactor,
    targetY: sourceY - (dx / length) * distanceFromEdge * flipFactor,
  });

  // Get the straight path for the end line
  const [endLinePath] = getStraightPath({
    sourceX: targetX,
    sourceY: targetY,
    targetX: targetX + (dy / length) * distanceFromEdge * flipFactor,
    targetY: targetY - (dx / length) * distanceFromEdge * flipFactor,
  });

  return {
    startLinePath,
    measurementLinePath,
    endLinePath,
    midpoint: { x: (sourceX + targetX) / 2 + normX, y: (sourceY + targetY) / 2 + normY },
  };
};
