import { isOverwrapDesignPart } from '@senrasystems/senra-ui';
import { getPatternType } from '../../../../utils/patterns.ts';
import { patternComponentMap } from '../../../components/DynamicPatternIcon.tsx';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';

const emptyPatterns = { pattern: null, selectedPattern: null };

export const useOverwrapPatterns = () => {
  const { getOverwrapByPartNumber } = useDesignParts();

  const getPatternsForOutermostOverwrap = (overwraps: string[]) => {
    if (overwraps.length > 0) {
      const outermostOverwrapPartNumber = overwraps[overwraps.length - 1];
      const overwrap = getOverwrapByPartNumber(outermostOverwrapPartNumber);

      if (overwrap && isOverwrapDesignPart(overwrap)) {
        const patternType = getPatternType(overwrap.partData.subtype);
        const patternColor = overwrap.partData.color;
        const patternId = overwrap.partData.partNumber;
        const selectedPatternId = `${overwrap.partData.partNumber}-selected`;
        if (patternType) {
          const PatternComponent = patternComponentMap[patternType];

          if (patternType && PatternComponent && patternColor) {
            return {
              pattern: { patternId, patternComponent: <PatternComponent id={patternId} color={patternColor} /> },
              selectedPattern: {
                patternId: selectedPatternId,
                patternComponent: <PatternComponent id={selectedPatternId} color={patternColor} />,
              },
            };
          }
        }
      }
    }

    return emptyPatterns;
  };

  return { getPatternsForOutermostOverwrap };
};
