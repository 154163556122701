import { PassiveNode } from '../../components/nodes/PassiveNode/PassiveNode.tsx';
import { NodeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { useActionsBase } from './useActionsBase.tsx';
import { usePartFlagNoteActions } from './flagNotes/usePartFlagNoteActions.tsx';

/**
 * Hook for passive actions.
 */
export const usePassiveActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const node = element as PassiveNode;

  const { flagNoteActions } = usePartFlagNoteActions(closeMenu, node);

  const menu = renderMenu(flagNoteActions);

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.Passive,
  } as MenuActions;
};
