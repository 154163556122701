import { doRequest, getUrl } from '../common/api';
import { InsertArrangement, PageData, Part, PartType, UpdatePartParams } from '@senrasystems/senra-ui';

export interface PartsResponse {
  data: Part[];
  meta: PageData;
}

export interface InsertArrangementsResponse {
  data: InsertArrangement[];
  meta: PageData;
}

export const DEFAULT_PARTS_PAGE_SIZE = 10;

export const NON_UPDATABLE_FIELDS = Object.freeze([
  'id',
  'type',
  'insertArrangement',
  'manufacturer',
  'createdAt',
  'creator',
  'deletedAt',
  'updatedAt',
  'updater',
]);

export const apiGetPart = (partId: string): Promise<Part | undefined> => {
  return doRequest<Part>('GET', getUrl(`/api/v1/parts/${partId}`));
};

export const apiGetParts = (
  partType?: PartType,
  query?: string | null,
  page?: string,
  pageSize?: number,
): Promise<PartsResponse | undefined> => {
  const endpoint = '/api/v1/parts';
  const params = new URLSearchParams({ per: `${pageSize ?? DEFAULT_PARTS_PAGE_SIZE}` });
  partType && params.set('type', partType);
  page && params.set('page', page);
  query && params.set('part_number', query);

  return doRequest<PartsResponse>('GET', getUrl(`${endpoint}?${params.toString()}`));
};

export const apiPostPart = (part: UpdatePartParams): Promise<Part | undefined> => {
  return doRequest<Part>('POST', getUrl('/api/v1/parts'), JSON.stringify({ part }));
};

export const apiPutPart = (part: UpdatePartParams): Promise<Part | undefined> => {
  const updatablePart = Object.fromEntries(Object.entries(part).filter(([key]) => !NON_UPDATABLE_FIELDS.includes(key)));
  return doRequest<Part>('PUT', getUrl(`/api/v1/parts/${part.id}`), JSON.stringify({ part: updatablePart }));
};

export const apiDeletePart = (partId: string): Promise<undefined> => {
  return doRequest('DELETE', getUrl(`/api/v1/parts/${partId}`));
};

export const apiGetInsertArrangement = (insertArrangementId: string): Promise<InsertArrangement | undefined> => {
  return doRequest<InsertArrangement>('GET', getUrl(`/api/v1/insert_arrangements/${insertArrangementId}`));
};

export const apiGetInsertArrangements = async (
  query?: string,
  page?: string,
  pageSize?: number,
): Promise<InsertArrangementsResponse | undefined> => {
  const endpoint = '/api/v1/insert_arrangements';
  const params = new URLSearchParams({ per: `${pageSize ?? DEFAULT_PARTS_PAGE_SIZE}` });
  query && params.set('q', query);
  page && params.set('page', page);

  return doRequest('GET', getUrl(`${endpoint}?${params.toString()}`));
};

export const apiPostInsertArrangement = (
  insertArrangement: InsertArrangement,
): Promise<InsertArrangement | undefined> => {
  return doRequest('POST', getUrl('/api/v1/insert_arrangements'), JSON.stringify({ insertArrangement }));
};

export const apiPutInsertArrangement = (
  insertArrangement: InsertArrangement,
): Promise<InsertArrangement | undefined> => {
  return doRequest<InsertArrangement>(
    'PUT',
    getUrl(`/api/v1/insert_arrangements/${insertArrangement.id}`),
    JSON.stringify({ insertArrangement }),
  );
};

export const apiDeleteInsertArrangement = (insertArrangementId: string): Promise<undefined> => {
  return doRequest('DELETE', getUrl(`/api/v1/insert_arrangements/${insertArrangementId}`));
};
