import { usePartData } from '../../../../hooks/usePartData.tsx';
import FieldValueList from '../../../../components/FieldValueList.tsx';
import { isDesignPartNode } from '../../types.ts';
import { Edge, Node } from '@xyflow/react';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';

export interface DesignPartViewProps {
  node?: Node;
  edge?: Edge;
}

export const DesignPartView = ({ node }: DesignPartViewProps) => {
  const designPartId = node && isDesignPartNode(node) ? node.data.designPartId : null;
  const { getDesignPartById } = useDesignParts();
  const data = usePartData(getDesignPartById(designPartId));

  return <FieldValueList data={data} p={4} />;
};
