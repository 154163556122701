import TextInput from '../components/form/TextInput';
import SubmitButton from '../components/buttons/SubmitButton';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useInvitedUser } from '../queries/users';
import { successToast } from '../common/toasts';
import { apiPutInvitedUser } from '../api/profile-api';
import { RouteNames } from '@web/consts/routeNames.ts';
import passwordValidator from 'password-validator';
import LogoText from '../components/svg/LogoText';
import CheckboxInput from '../components/form/CheckboxInput';
import { privacyPolicyUrl } from '../common/socials';
import { Center, IconButton, Link, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

const Consent = () => (
  <span>
    I have read and consent to the Senra Systems{' '}
    <Link href={privacyPolicyUrl} target="_blank" textDecoration="underline" color="purple.400">
      Privacy Policy
    </Link>
    .
  </span>
);

const Register = () => {
  const { userId = '' } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm();

  const { isLoading, data: user } = useInvitedUser(userId);

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user, reset]);

  const onSubmit = async ({ name, password }: { name: string; password: string }) => {
    const results = await apiPutInvitedUser(userId, { name, password });
    if (results) {
      successToast('User successfully registered.');
      navigate(RouteNames.ORDERS.INDEX);
    }
  };

  const bgColor = useColorModeValue('gray.100', 'gray.700');
  if (isLoading) return;
  if (!user) navigate(RouteNames.ROOT);

  const passwordSchema = new passwordValidator()
    .is()
    .min(8)
    .has()
    .lowercase()
    .has()
    .uppercase()
    .has()
    .digits()
    .has()
    .symbols();
  const isValidPassword = (password: string) => passwordSchema.validate(password) as boolean;

  return (
    <Center h="100%">
      <Stack bgColor={bgColor} p={10} w="500px" spacing={6}>
        <Center>
          <LogoText width={350} />
        </Center>
        <Text>
          Welcome to the Senra Systems Customer Portal! Please fill out your name and create a password for your
          account. Once you have finished registration, you will be redirected to the login page.
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <TextInput label="Email" isDisabled {...register('email')} />
            <TextInput label="Name *" {...register('name', { required: true })} />
            <TextInput
              label="Password *"
              type={showPassword ? 'text' : 'password'}
              hint="At least 8 characters with one uppercase, one lowercase, one digit, and one symbol."
              inputRightElement={
                <IconButton
                  aria-label="Show password"
                  icon={showPassword ? <BsEyeSlash /> : <BsEye />}
                  onClick={() => setShowPassword(!showPassword)}
                />
              }
              {...register('password', { required: true, validate: isValidPassword })}
            />
            <CheckboxInput label={<Consent />} {...register('consent', { required: true })} />
            <Center>
              <SubmitButton loading={isSubmitting} disabled={!isValid} text="Register" />
            </Center>
          </Stack>
        </form>
      </Stack>
    </Center>
  );
};

export default Register;
