import { Tooltip } from '@chakra-ui/react';
import { BuildNote } from '@senrasystems/senra-ui';
import { TrianglePosition } from '../../notes/TrianglePosition';

interface Props {
  flagNote: BuildNote;
  disableTooltip: boolean;
}

export const LayoutFlagNote = ({ flagNote, disableTooltip }: Props) => {
  const { body, position } = flagNote;

  return (
    <Tooltip whiteSpace="pre-wrap" label={body} isDisabled={disableTooltip}>
      <TrianglePosition position={position} />
    </Tooltip>
  );
};
