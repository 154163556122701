import { BaseEdge, Edge, EdgeProps, getSmoothStepPath, Position } from '@xyflow/react';
import { defaultLayoutConfig } from '../../../config.ts';
import { useCalculateTargetDirection } from '../../../hooks/notes/useCalculateTargetDirection.ts';

export type PartNoteEdge = Edge;

// Define the style for the segment
const segmentStyle = {
  stroke: defaultLayoutConfig.edgeColor,
  strokeWidth: 1,
};

/**
 * Note edge component connecting the flag notes to the indicated element.
 * @constructor
 * @param props
 */
export const PartNoteEdge = ({ source, target, sourceX, sourceY, targetX, targetY }: EdgeProps<PartNoteEdge>) => {
  const { horizontal, vertical } = useCalculateTargetDirection(source, target);

  const isTargetBelow = vertical === Position.Bottom;
  const targetPosition = isTargetBelow ? Position.Top : Position.Bottom;
  const offsetY = isTargetBelow ? -25 : 30;

  // Get the edge path for the note edge
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition: horizontal,
    targetX,
    targetY: targetY + offsetY,
    targetPosition,
  });

  return <BaseEdge path={edgePath} markerEnd={'url(#flag-note-arrow)'} style={segmentStyle} />;
};
