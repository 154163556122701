import { ShapeProps } from '../../../types/shapes.ts';

export const CapacitorShape = ({ color }: ShapeProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        d="M15 7.25C15.4142 7.25 15.75 7.58579 15.75 8V10.25H20C20.4142 10.25 20.75 10.5858 20.75 11C20.75 11.4142 20.4142 11.75 20 11.75H15.75V14C15.75 14.4142 15.4142 14.75 15 14.75C14.5858 14.75 14.25 14.4142 14.25 14V8C14.25 7.58579 14.5858 7.25 15 7.25Z"
        fill="white"
      />
      <path
        d="M3 10.25C2.58579 10.25 2.25 10.5858 2.25 11C2.25 11.4142 2.58579 11.75 3 11.75H7.25V14C7.25 14.4142 7.58579 14.75 8 14.75C8.41421 14.75 8.75 14.4142 8.75 14V11.0006V8C8.75 7.58579 8.41421 7.25 8 7.25C7.58579 7.25 7.25 7.58579 7.25 8V10.25H3Z"
        fill="white"
      />
    </svg>
  );
};
