import { Box, HStack, Text } from '@chakra-ui/react';
import { isCableDesignPart, isWireDesignPart } from '@senrasystems/senra-ui';
import { useConnections } from '../../../../../hooks/useConnections.tsx';

interface ConnectionRowProps {
  connectionId: string;
}

export const ConnectionRow = ({ connectionId }: ConnectionRowProps) => {
  const { getConnectionById } = useConnections();
  const connection = getConnectionById(connectionId);

  if (!connection?.conductor?.designPart) {
    return null;
  }

  const conductor = connection.conductor.designPart;

  if (isCableDesignPart(conductor)) {
    return (
      <HStack w="full" p={2} spacing={4} _hover={{ bg: 'gray.50' }}>
        <Text minW="50px">{conductor.name}</Text>
        <Text minW="100px">{conductor.partData.partNumber}</Text>
        <Text>
          {conductor.partData.partWires.map((wire) => (wire.wire?.gauge ? `${wire.wire.gauge} AWG` : '')).join(', ')}
        </Text>
        <HStack flex="1" spacing={0} justifyContent="flex-end">
          <Box w="4" h="4" bg={conductor.partData.jacket || 'white'} borderWidth={1} borderColor="gray.300" />
        </HStack>
      </HStack>
    );
  }

  if (isWireDesignPart(conductor)) {
    return (
      <HStack w="full" p={2} spacing={4} _hover={{ bg: 'gray.50' }}>
        <Text minW="50px">{conductor.name}</Text>
        <Text minW="100px">{conductor.partData.partNumber}</Text>
        <Text minW="100px">{conductor.partData.gauge} AWG</Text>
        <HStack flex="1" justifyContent="flex-end">
          <HStack spacing={0} borderWidth={1} borderColor="gray.300">
            {conductor.partData.colors.map((color, index) => (
              <Box key={`${color}-${index}`} w="4" h="4" bg={color} />
            ))}
          </HStack>
        </HStack>
      </HStack>
    );
  }

  return null;
};
